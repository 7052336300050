import Alpine from "alpinejs";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import "../css/main.ts";

// Start alpine.js
Alpine.start();

function searchFunc() {
  // Change search text for icon...
  // Make sure we have the search icon image...
  const searchIcon: HTMLImageElement | null = document.querySelector(
    "#search-toggle-image"
  );
  if (searchIcon) {
    const searchInput: HTMLInputElement | null = document.querySelector(
      "#searchform input[type='text']"
    );
    const button: HTMLInputElement | null = document.querySelector(
      "#searchform input[type='submit']"
    );
    if (button && searchInput) {
      // Focus the search input when clicking the searchIcon
      searchIcon.addEventListener("click", () => {
        setTimeout(() => searchInput.focus(), 250);
      });

      button.value = "";
      button.style.width = "28px";
      button.style.height = "28px";
      button.style.backgroundSize = "contain";
      button.style.backgroundRepeat = "no-repeat";
      button.style.backgroundImage = `url(${searchIcon.src})`;
    }
  }
}

// This makes the entire grid cards clickable
function clickableGridCards() {
  document.querySelectorAll(".axis-posts-grid-item").forEach((el) => {
    el.addEventListener("click", () => {
      const link = el.querySelector("a");
      link && link.click();
    });
  });
}

document.addEventListener("DOMContentLoaded", () => {
  // Code highlighting
  document.querySelectorAll("code").forEach((el) => {
    hljs.highlightElement(el as HTMLElement);
  });

  searchFunc();
  clickableGridCards();
});
