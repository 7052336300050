/*
Language: Maxima
Author: Robert Dodier <robert.dodier@gmail.com>
Website: http://maxima.sourceforge.net
Category: scientific
*/

function maxima(hljs) {
  const KEYWORDS =
    'if then else elseif for thru do while unless step in and or not';
  const LITERALS =
    'true false unknown inf minf ind und %e %i %pi %phi %gamma';
  const BUILTIN_FUNCTIONS =
    ' abasep abs absint absolute_real_time acos acosh acot acoth acsc acsch activate'
    + ' addcol add_edge add_edges addmatrices addrow add_vertex add_vertices adjacency_matrix'
    + ' adjoin adjoint af agd airy airy_ai airy_bi airy_dai airy_dbi algsys alg_type'
    + ' alias allroots alphacharp alphanumericp amortization %and annuity_fv'
    + ' annuity_pv antid antidiff AntiDifference append appendfile apply apply1 apply2'
    + ' applyb1 apropos args arit_amortization arithmetic arithsum array arrayapply'
    + ' arrayinfo arraymake arraysetapply ascii asec asech asin asinh askinteger'
    + ' asksign assoc assoc_legendre_p assoc_legendre_q assume assume_external_byte_order'
    + ' asympa at atan atan2 atanh atensimp atom atvalue augcoefmatrix augmented_lagrangian_method'
    + ' av average_degree backtrace bars barsplot barsplot_description base64 base64_decode'
    + ' bashindices batch batchload bc2 bdvac belln benefit_cost bern bernpoly bernstein_approx'
    + ' bernstein_expand bernstein_poly bessel bessel_i bessel_j bessel_k bessel_simplify'
    + ' bessel_y beta beta_incomplete beta_incomplete_generalized beta_incomplete_regularized'
    + ' bezout bfallroots bffac bf_find_root bf_fmin_cobyla bfhzeta bfloat bfloatp'
    + ' bfpsi bfpsi0 bfzeta biconnected_components bimetric binomial bipartition'
    + ' block blockmatrixp bode_gain bode_phase bothcoef box boxplot boxplot_description'
    + ' break bug_report build_info|10 buildq build_sample burn cabs canform canten'
    + ' cardinality carg cartan cartesian_product catch cauchy_matrix cbffac cdf_bernoulli'
    + ' cdf_beta cdf_binomial cdf_cauchy cdf_chi2 cdf_continuous_uniform cdf_discrete_uniform'
    + ' cdf_exp cdf_f cdf_gamma cdf_general_finite_discrete cdf_geometric cdf_gumbel'
    + ' cdf_hypergeometric cdf_laplace cdf_logistic cdf_lognormal cdf_negative_binomial'
    + ' cdf_noncentral_chi2 cdf_noncentral_student_t cdf_normal cdf_pareto cdf_poisson'
    + ' cdf_rank_sum cdf_rayleigh cdf_signed_rank cdf_student_t cdf_weibull cdisplay'
    + ' ceiling central_moment cequal cequalignore cf cfdisrep cfexpand cgeodesic'
    + ' cgreaterp cgreaterpignore changename changevar chaosgame charat charfun charfun2'
    + ' charlist charp charpoly chdir chebyshev_t chebyshev_u checkdiv check_overlaps'
    + ' chinese cholesky christof chromatic_index chromatic_number cint circulant_graph'
    + ' clear_edge_weight clear_rules clear_vertex_label clebsch_gordan clebsch_graph'
    + ' clessp clesspignore close closefile cmetric coeff coefmatrix cograd col collapse'
    + ' collectterms columnop columnspace columnswap columnvector combination combine'
    + ' comp2pui compare compfile compile compile_file complement_graph complete_bipartite_graph'
    + ' complete_graph complex_number_p components compose_functions concan concat'
    + ' conjugate conmetderiv connected_components connect_vertices cons constant'
    + ' constantp constituent constvalue cont2part content continuous_freq contortion'
    + ' contour_plot contract contract_edge contragrad contrib_ode convert coord'
    + ' copy copy_file copy_graph copylist copymatrix cor cos cosh cot coth cov cov1'
    + ' covdiff covect covers crc24sum create_graph create_list csc csch csetup cspline'
    + ' ctaylor ct_coordsys ctransform ctranspose cube_graph cuboctahedron_graph'
    + ' cunlisp cv cycle_digraph cycle_graph cylindrical days360 dblint deactivate'
    + ' declare declare_constvalue declare_dimensions declare_fundamental_dimensions'
    + ' declare_fundamental_units declare_qty declare_translated declare_unit_conversion'
    + ' declare_units declare_weights decsym defcon define define_alt_display define_variable'
    + ' defint defmatch defrule defstruct deftaylor degree_sequence del delete deleten'
    + ' delta demo demoivre denom depends derivdegree derivlist describe desolve'
    + ' determinant dfloat dgauss_a dgauss_b dgeev dgemm dgeqrf dgesv dgesvd diag'
    + ' diagmatrix diag_matrix diagmatrixp diameter diff digitcharp dimacs_export'
    + ' dimacs_import dimension dimensionless dimensions dimensions_as_list direct'
    + ' directory discrete_freq disjoin disjointp disolate disp dispcon dispform'
    + ' dispfun dispJordan display disprule dispterms distrib divide divisors divsum'
    + ' dkummer_m dkummer_u dlange dodecahedron_graph dotproduct dotsimp dpart'
    + ' draw draw2d draw3d drawdf draw_file draw_graph dscalar echelon edge_coloring'
    + ' edge_connectivity edges eigens_by_jacobi eigenvalues eigenvectors eighth'
    + ' einstein eivals eivects elapsed_real_time elapsed_run_time ele2comp ele2polynome'
    + ' ele2pui elem elementp elevation_grid elim elim_allbut eliminate eliminate_using'
    + ' ellipse elliptic_e elliptic_ec elliptic_eu elliptic_f elliptic_kc elliptic_pi'
    + ' ematrix empty_graph emptyp endcons entermatrix entertensor entier equal equalp'
    + ' equiv_classes erf erfc erf_generalized erfi errcatch error errormsg errors'
    + ' euler ev eval_string evenp every evolution evolution2d evundiff example exp'
    + ' expand expandwrt expandwrt_factored expint expintegral_chi expintegral_ci'
    + ' expintegral_e expintegral_e1 expintegral_ei expintegral_e_simplify expintegral_li'
    + ' expintegral_shi expintegral_si explicit explose exponentialize express expt'
    + ' exsec extdiff extract_linear_equations extremal_subset ezgcd %f f90 facsum'
    + ' factcomb factor factorfacsum factorial factorout factorsum facts fast_central_elements'
    + ' fast_linsolve fasttimes featurep fernfale fft fib fibtophi fifth filename_merge'
    + ' file_search file_type fillarray findde find_root find_root_abs find_root_error'
    + ' find_root_rel first fix flatten flength float floatnump floor flower_snark'
    + ' flush flush1deriv flushd flushnd flush_output fmin_cobyla forget fortran'
    + ' fourcos fourexpand fourier fourier_elim fourint fourintcos fourintsin foursimp'
    + ' foursin fourth fposition frame_bracket freeof freshline fresnel_c fresnel_s'
    + ' from_adjacency_matrix frucht_graph full_listify fullmap fullmapl fullratsimp'
    + ' fullratsubst fullsetify funcsolve fundamental_dimensions fundamental_units'
    + ' fundef funmake funp fv g0 g1 gamma gamma_greek gamma_incomplete gamma_incomplete_generalized'
    + ' gamma_incomplete_regularized gauss gauss_a gauss_b gaussprob gcd gcdex gcdivide'
    + ' gcfac gcfactor gd generalized_lambert_w genfact gen_laguerre genmatrix gensym'
    + ' geo_amortization geo_annuity_fv geo_annuity_pv geomap geometric geometric_mean'
    + ' geosum get getcurrentdirectory get_edge_weight getenv get_lu_factors get_output_stream_string'
    + ' get_pixel get_plot_option get_tex_environment get_tex_environment_default'
    + ' get_vertex_label gfactor gfactorsum ggf girth global_variances gn gnuplot_close'
    + ' gnuplot_replot gnuplot_reset gnuplot_restart gnuplot_start go Gosper GosperSum'
    + ' gr2d gr3d gradef gramschmidt graph6_decode graph6_encode graph6_export graph6_import'
    + ' graph_center graph_charpoly graph_eigenvalues graph_flow graph_order graph_periphery'
    + ' graph_product graph_size graph_union great_rhombicosidodecahedron_graph great_rhombicuboctahedron_graph'
    + ' grid_graph grind grobner_basis grotzch_graph hamilton_cycle hamilton_path'
    + ' hankel hankel_1 hankel_2 harmonic harmonic_mean hav heawood_graph hermite'
    + ' hessian hgfred hilbertmap hilbert_matrix hipow histogram histogram_description'
    + ' hodge horner hypergeometric i0 i1 %ibes ic1 ic2 ic_convert ichr1 ichr2 icosahedron_graph'
    + ' icosidodecahedron_graph icurvature ident identfor identity idiff idim idummy'
    + ' ieqn %if ifactors iframes ifs igcdex igeodesic_coords ilt image imagpart'
    + ' imetric implicit implicit_derivative implicit_plot indexed_tensor indices'
    + ' induced_subgraph inferencep inference_result infix info_display init_atensor'
    + ' init_ctensor in_neighbors innerproduct inpart inprod inrt integerp integer_partitions'
    + ' integrate intersect intersection intervalp intopois intosum invariant1 invariant2'
    + ' inverse_fft inverse_jacobi_cd inverse_jacobi_cn inverse_jacobi_cs inverse_jacobi_dc'
    + ' inverse_jacobi_dn inverse_jacobi_ds inverse_jacobi_nc inverse_jacobi_nd inverse_jacobi_ns'
    + ' inverse_jacobi_sc inverse_jacobi_sd inverse_jacobi_sn invert invert_by_adjoint'
    + ' invert_by_lu inv_mod irr is is_biconnected is_bipartite is_connected is_digraph'
    + ' is_edge_in_graph is_graph is_graph_or_digraph ishow is_isomorphic isolate'
    + ' isomorphism is_planar isqrt isreal_p is_sconnected is_tree is_vertex_in_graph'
    + ' items_inference %j j0 j1 jacobi jacobian jacobi_cd jacobi_cn jacobi_cs jacobi_dc'
    + ' jacobi_dn jacobi_ds jacobi_nc jacobi_nd jacobi_ns jacobi_p jacobi_sc jacobi_sd'
    + ' jacobi_sn JF jn join jordan julia julia_set julia_sin %k kdels kdelta kill'
    + ' killcontext kostka kron_delta kronecker_product kummer_m kummer_u kurtosis'
    + ' kurtosis_bernoulli kurtosis_beta kurtosis_binomial kurtosis_chi2 kurtosis_continuous_uniform'
    + ' kurtosis_discrete_uniform kurtosis_exp kurtosis_f kurtosis_gamma kurtosis_general_finite_discrete'
    + ' kurtosis_geometric kurtosis_gumbel kurtosis_hypergeometric kurtosis_laplace'
    + ' kurtosis_logistic kurtosis_lognormal kurtosis_negative_binomial kurtosis_noncentral_chi2'
    + ' kurtosis_noncentral_student_t kurtosis_normal kurtosis_pareto kurtosis_poisson'
    + ' kurtosis_rayleigh kurtosis_student_t kurtosis_weibull label labels lagrange'
    + ' laguerre lambda lambert_w laplace laplacian_matrix last lbfgs lc2kdt lcharp'
    + ' lc_l lcm lc_u ldefint ldisp ldisplay legendre_p legendre_q leinstein length'
    + ' let letrules letsimp levi_civita lfreeof lgtreillis lhs li liediff limit'
    + ' Lindstedt linear linearinterpol linear_program linear_regression line_graph'
    + ' linsolve listarray list_correlations listify list_matrix_entries list_nc_monomials'
    + ' listoftens listofvars listp lmax lmin load loadfile local locate_matrix_entry'
    + ' log logcontract log_gamma lopow lorentz_gauge lowercasep lpart lratsubst'
    + ' lreduce lriemann lsquares_estimates lsquares_estimates_approximate lsquares_estimates_exact'
    + ' lsquares_mse lsquares_residual_mse lsquares_residuals lsum ltreillis lu_backsub'
    + ' lucas lu_factor %m macroexpand macroexpand1 make_array makebox makefact makegamma'
    + ' make_graph make_level_picture makelist makeOrders make_poly_continent make_poly_country'
    + ' make_polygon make_random_state make_rgb_picture makeset make_string_input_stream'
    + ' make_string_output_stream make_transform mandelbrot mandelbrot_set map mapatom'
    + ' maplist matchdeclare matchfix mat_cond mat_fullunblocker mat_function mathml_display'
    + ' mat_norm matrix matrixmap matrixp matrix_size mattrace mat_trace mat_unblocker'
    + ' max max_clique max_degree max_flow maximize_lp max_independent_set max_matching'
    + ' maybe md5sum mean mean_bernoulli mean_beta mean_binomial mean_chi2 mean_continuous_uniform'
    + ' mean_deviation mean_discrete_uniform mean_exp mean_f mean_gamma mean_general_finite_discrete'
    + ' mean_geometric mean_gumbel mean_hypergeometric mean_laplace mean_logistic'
    + ' mean_lognormal mean_negative_binomial mean_noncentral_chi2 mean_noncentral_student_t'
    + ' mean_normal mean_pareto mean_poisson mean_rayleigh mean_student_t mean_weibull'
    + ' median median_deviation member mesh metricexpandall mgf1_sha1 min min_degree'
    + ' min_edge_cut minfactorial minimalPoly minimize_lp minimum_spanning_tree minor'
    + ' minpack_lsquares minpack_solve min_vertex_cover min_vertex_cut mkdir mnewton'
    + ' mod mode_declare mode_identity ModeMatrix moebius mon2schur mono monomial_dimensions'
    + ' multibernstein_poly multi_display_for_texinfo multi_elem multinomial multinomial_coeff'
    + ' multi_orbit multiplot_mode multi_pui multsym multthru mycielski_graph nary'
    + ' natural_unit nc_degree ncexpt ncharpoly negative_picture neighbors new newcontext'
    + ' newdet new_graph newline newton new_variable next_prime nicedummies niceindices'
    + ' ninth nofix nonarray noncentral_moment nonmetricity nonnegintegerp nonscalarp'
    + ' nonzeroandfreeof notequal nounify nptetrad npv nroots nterms ntermst'
    + ' nthroot nullity nullspace num numbered_boundaries numberp number_to_octets'
    + ' num_distinct_partitions numerval numfactor num_partitions nusum nzeta nzetai'
    + ' nzetar octets_to_number octets_to_oid odd_girth oddp ode2 ode_check odelin'
    + ' oid_to_octets op opena opena_binary openr openr_binary openw openw_binary'
    + ' operatorp opsubst optimize %or orbit orbits ordergreat ordergreatp orderless'
    + ' orderlessp orthogonal_complement orthopoly_recur orthopoly_weight outermap'
    + ' out_neighbors outofpois pade parabolic_cylinder_d parametric parametric_surface'
    + ' parg parGosper parse_string parse_timedate part part2cont partfrac partition'
    + ' partition_set partpol path_digraph path_graph pathname_directory pathname_name'
    + ' pathname_type pdf_bernoulli pdf_beta pdf_binomial pdf_cauchy pdf_chi2 pdf_continuous_uniform'
    + ' pdf_discrete_uniform pdf_exp pdf_f pdf_gamma pdf_general_finite_discrete'
    + ' pdf_geometric pdf_gumbel pdf_hypergeometric pdf_laplace pdf_logistic pdf_lognormal'
    + ' pdf_negative_binomial pdf_noncentral_chi2 pdf_noncentral_student_t pdf_normal'
    + ' pdf_pareto pdf_poisson pdf_rank_sum pdf_rayleigh pdf_signed_rank pdf_student_t'
    + ' pdf_weibull pearson_skewness permanent permut permutation permutations petersen_graph'
    + ' petrov pickapart picture_equalp picturep piechart piechart_description planar_embedding'
    + ' playback plog plot2d plot3d plotdf ploteq plsquares pochhammer points poisdiff'
    + ' poisexpt poisint poismap poisplus poissimp poissubst poistimes poistrim polar'
    + ' polarform polartorect polar_to_xy poly_add poly_buchberger poly_buchberger_criterion'
    + ' poly_colon_ideal poly_content polydecomp poly_depends_p poly_elimination_ideal'
    + ' poly_exact_divide poly_expand poly_expt poly_gcd polygon poly_grobner poly_grobner_equal'
    + ' poly_grobner_member poly_grobner_subsetp poly_ideal_intersection poly_ideal_polysaturation'
    + ' poly_ideal_polysaturation1 poly_ideal_saturation poly_ideal_saturation1 poly_lcm'
    + ' poly_minimization polymod poly_multiply polynome2ele polynomialp poly_normal_form'
    + ' poly_normalize poly_normalize_list poly_polysaturation_extension poly_primitive_part'
    + ' poly_pseudo_divide poly_reduced_grobner poly_reduction poly_saturation_extension'
    + ' poly_s_polynomial poly_subtract polytocompanion pop postfix potential power_mod'
    + ' powerseries powerset prefix prev_prime primep primes principal_components'
    + ' print printf printfile print_graph printpois printprops prodrac product properties'
    + ' propvars psi psubst ptriangularize pui pui2comp pui2ele pui2polynome pui_direct'
    + ' puireduc push put pv qput qrange qty quad_control quad_qag quad_qagi quad_qagp'
    + ' quad_qags quad_qawc quad_qawf quad_qawo quad_qaws quadrilateral quantile'
    + ' quantile_bernoulli quantile_beta quantile_binomial quantile_cauchy quantile_chi2'
    + ' quantile_continuous_uniform quantile_discrete_uniform quantile_exp quantile_f'
    + ' quantile_gamma quantile_general_finite_discrete quantile_geometric quantile_gumbel'
    + ' quantile_hypergeometric quantile_laplace quantile_logistic quantile_lognormal'
    + ' quantile_negative_binomial quantile_noncentral_chi2 quantile_noncentral_student_t'
    + ' quantile_normal quantile_pareto quantile_poisson quantile_rayleigh quantile_student_t'
    + ' quantile_weibull quartile_skewness quit qunit quotient racah_v racah_w radcan'
    + ' radius random random_bernoulli random_beta random_binomial random_bipartite_graph'
    + ' random_cauchy random_chi2 random_continuous_uniform random_digraph random_discrete_uniform'
    + ' random_exp random_f random_gamma random_general_finite_discrete random_geometric'
    + ' random_graph random_graph1 random_gumbel random_hypergeometric random_laplace'
    + ' random_logistic random_lognormal random_negative_binomial random_network'
    + ' random_noncentral_chi2 random_noncentral_student_t random_normal random_pareto'
    + ' random_permutation random_poisson random_rayleigh random_regular_graph random_student_t'
    + ' random_tournament random_tree random_weibull range rank rat ratcoef ratdenom'
    + ' ratdiff ratdisrep ratexpand ratinterpol rational rationalize ratnumer ratnump'
    + ' ratp ratsimp ratsubst ratvars ratweight read read_array read_binary_array'
    + ' read_binary_list read_binary_matrix readbyte readchar read_hashed_array readline'
    + ' read_list read_matrix read_nested_list readonly read_xpm real_imagpart_to_conjugate'
    + ' realpart realroots rearray rectangle rectform rectform_log_if_constant recttopolar'
    + ' rediff reduce_consts reduce_order region region_boundaries region_boundaries_plus'
    + ' rem remainder remarray rembox remcomps remcon remcoord remfun remfunction'
    + ' remlet remove remove_constvalue remove_dimensions remove_edge remove_fundamental_dimensions'
    + ' remove_fundamental_units remove_plot_option remove_vertex rempart remrule'
    + ' remsym remvalue rename rename_file reset reset_displays residue resolvante'
    + ' resolvante_alternee1 resolvante_bipartite resolvante_diedrale resolvante_klein'
    + ' resolvante_klein3 resolvante_produit_sym resolvante_unitaire resolvante_vierer'
    + ' rest resultant return reveal reverse revert revert2 rgb2level rhs ricci riemann'
    + ' rinvariant risch rk rmdir rncombine romberg room rootscontract round row'
    + ' rowop rowswap rreduce run_testsuite %s save saving scalarp scaled_bessel_i'
    + ' scaled_bessel_i0 scaled_bessel_i1 scalefactors scanmap scatterplot scatterplot_description'
    + ' scene schur2comp sconcat scopy scsimp scurvature sdowncase sec sech second'
    + ' sequal sequalignore set_alt_display setdifference set_draw_defaults set_edge_weight'
    + ' setelmx setequalp setify setp set_partitions set_plot_option set_prompt set_random_state'
    + ' set_tex_environment set_tex_environment_default setunits setup_autoload set_up_dot_simplifications'
    + ' set_vertex_label seventh sexplode sf sha1sum sha256sum shortest_path shortest_weighted_path'
    + ' show showcomps showratvars sierpinskiale sierpinskimap sign signum similaritytransform'
    + ' simp_inequality simplify_sum simplode simpmetderiv simtran sin sinh sinsert'
    + ' sinvertcase sixth skewness skewness_bernoulli skewness_beta skewness_binomial'
    + ' skewness_chi2 skewness_continuous_uniform skewness_discrete_uniform skewness_exp'
    + ' skewness_f skewness_gamma skewness_general_finite_discrete skewness_geometric'
    + ' skewness_gumbel skewness_hypergeometric skewness_laplace skewness_logistic'
    + ' skewness_lognormal skewness_negative_binomial skewness_noncentral_chi2 skewness_noncentral_student_t'
    + ' skewness_normal skewness_pareto skewness_poisson skewness_rayleigh skewness_student_t'
    + ' skewness_weibull slength smake small_rhombicosidodecahedron_graph small_rhombicuboctahedron_graph'
    + ' smax smin smismatch snowmap snub_cube_graph snub_dodecahedron_graph solve'
    + ' solve_rec solve_rec_rat some somrac sort sparse6_decode sparse6_encode sparse6_export'
    + ' sparse6_import specint spherical spherical_bessel_j spherical_bessel_y spherical_hankel1'
    + ' spherical_hankel2 spherical_harmonic spherical_to_xyz splice split sposition'
    + ' sprint sqfr sqrt sqrtdenest sremove sremovefirst sreverse ssearch ssort sstatus'
    + ' ssubst ssubstfirst staircase standardize standardize_inverse_trig starplot'
    + ' starplot_description status std std1 std_bernoulli std_beta std_binomial'
    + ' std_chi2 std_continuous_uniform std_discrete_uniform std_exp std_f std_gamma'
    + ' std_general_finite_discrete std_geometric std_gumbel std_hypergeometric std_laplace'
    + ' std_logistic std_lognormal std_negative_binomial std_noncentral_chi2 std_noncentral_student_t'
    + ' std_normal std_pareto std_poisson std_rayleigh std_student_t std_weibull'
    + ' stemplot stirling stirling1 stirling2 strim striml strimr string stringout'
    + ' stringp strong_components struve_h struve_l sublis sublist sublist_indices'
    + ' submatrix subsample subset subsetp subst substinpart subst_parallel substpart'
    + ' substring subvar subvarp sum sumcontract summand_to_rec supcase supcontext'
    + ' symbolp symmdifference symmetricp system take_channel take_inference tan'
    + ' tanh taylor taylorinfo taylorp taylor_simplifier taytorat tcl_output tcontract'
    + ' tellrat tellsimp tellsimpafter tentex tenth test_mean test_means_difference'
    + ' test_normality test_proportion test_proportions_difference test_rank_sum'
    + ' test_sign test_signed_rank test_variance test_variance_ratio tex tex1 tex_display'
    + ' texput %th third throw time timedate timer timer_info tldefint tlimit todd_coxeter'
    + ' toeplitz tokens to_lisp topological_sort to_poly to_poly_solve totaldisrep'
    + ' totalfourier totient tpartpol trace tracematrix trace_options transform_sample'
    + ' translate translate_file transpose treefale tree_reduce treillis treinat'
    + ' triangle triangularize trigexpand trigrat trigreduce trigsimp trunc truncate'
    + ' truncated_cube_graph truncated_dodecahedron_graph truncated_icosahedron_graph'
    + ' truncated_tetrahedron_graph tr_warnings_get tube tutte_graph ueivects uforget'
    + ' ultraspherical underlying_graph undiff union unique uniteigenvectors unitp'
    + ' units unit_step unitvector unorder unsum untellrat untimer'
    + ' untrace uppercasep uricci uriemann uvect vandermonde_matrix var var1 var_bernoulli'
    + ' var_beta var_binomial var_chi2 var_continuous_uniform var_discrete_uniform'
    + ' var_exp var_f var_gamma var_general_finite_discrete var_geometric var_gumbel'
    + ' var_hypergeometric var_laplace var_logistic var_lognormal var_negative_binomial'
    + ' var_noncentral_chi2 var_noncentral_student_t var_normal var_pareto var_poisson'
    + ' var_rayleigh var_student_t var_weibull vector vectorpotential vectorsimp'
    + ' verbify vers vertex_coloring vertex_connectivity vertex_degree vertex_distance'
    + ' vertex_eccentricity vertex_in_degree vertex_out_degree vertices vertices_to_cycle'
    + ' vertices_to_path %w weyl wheel_graph wiener_index wigner_3j wigner_6j'
    + ' wigner_9j with_stdout write_binary_data writebyte write_data writefile wronskian'
    + ' xreduce xthru %y Zeilberger zeroequiv zerofor zeromatrix zeromatrixp zeta'
    + ' zgeev zheev zlange zn_add_table zn_carmichael_lambda zn_characteristic_factors'
    + ' zn_determinant zn_factor_generators zn_invert_by_lu zn_log zn_mult_table'
    + ' absboxchar activecontexts adapt_depth additive adim aform algebraic'
    + ' algepsilon algexact aliases allbut all_dotsimp_denoms allocation allsym alphabetic'
    + ' animation antisymmetric arrays askexp assume_pos assume_pos_pred assumescalar'
    + ' asymbol atomgrad atrig1 axes axis_3d axis_bottom axis_left axis_right axis_top'
    + ' azimuth background background_color backsubst berlefact bernstein_explicit'
    + ' besselexpand beta_args_sum_to_integer beta_expand bftorat bftrunc bindtest'
    + ' border boundaries_array box boxchar breakup %c capping cauchysum cbrange'
    + ' cbtics center cflength cframe_flag cnonmet_flag color color_bar color_bar_tics'
    + ' colorbox columns commutative complex cone context contexts contour contour_levels'
    + ' cosnpiflag ctaypov ctaypt ctayswitch ctayvar ct_coords ctorsion_flag ctrgsimp'
    + ' cube current_let_rule_package cylinder data_file_name debugmode decreasing'
    + ' default_let_rule_package delay dependencies derivabbrev derivsubst detout'
    + ' diagmetric diff dim dimensions dispflag display2d|10 display_format_internal'
    + ' distribute_over doallmxops domain domxexpt domxmxops domxnctimes dontfactor'
    + ' doscmxops doscmxplus dot0nscsimp dot0simp dot1simp dotassoc dotconstrules'
    + ' dotdistrib dotexptsimp dotident dotscrules draw_graph_program draw_realpart'
    + ' edge_color edge_coloring edge_partition edge_type edge_width %edispflag'
    + ' elevation %emode endphi endtheta engineering_format_floats enhanced3d %enumer'
    + ' epsilon_lp erfflag erf_representation errormsg error_size error_syms error_type'
    + ' %e_to_numlog eval even evenfun evflag evfun ev_point expandwrt_denom expintexpand'
    + ' expintrep expon expop exptdispflag exptisolate exptsubst facexpand facsum_combine'
    + ' factlim factorflag factorial_expand factors_only fb feature features'
    + ' file_name file_output_append file_search_demo file_search_lisp file_search_maxima|10'
    + ' file_search_tests file_search_usage file_type_lisp file_type_maxima|10 fill_color'
    + ' fill_density filled_func fixed_vertices flipflag float2bf font font_size'
    + ' fortindent fortspaces fpprec fpprintprec functions gamma_expand gammalim'
    + ' gdet genindex gensumnum GGFCFMAX GGFINFINITY globalsolve gnuplot_command'
    + ' gnuplot_curve_styles gnuplot_curve_titles gnuplot_default_term_command gnuplot_dumb_term_command'
    + ' gnuplot_file_args gnuplot_file_name gnuplot_out_file gnuplot_pdf_term_command'
    + ' gnuplot_pm3d gnuplot_png_term_command gnuplot_postamble gnuplot_preamble'
    + ' gnuplot_ps_term_command gnuplot_svg_term_command gnuplot_term gnuplot_view_args'
    + ' Gosper_in_Zeilberger gradefs grid grid2d grind halfangles head_angle head_both'
    + ' head_length head_type height hypergeometric_representation %iargs ibase'
    + ' icc1 icc2 icounter idummyx ieqnprint ifb ifc1 ifc2 ifg ifgi ifr iframe_bracket_form'
    + ' ifri igeowedge_flag ikt1 ikt2 imaginary inchar increasing infeval'
    + ' infinity inflag infolists inm inmc1 inmc2 intanalysis integer integervalued'
    + ' integrate_use_rootsof integration_constant integration_constant_counter interpolate_color'
    + ' intfaclim ip_grid ip_grid_in irrational isolate_wrt_times iterations itr'
    + ' julia_parameter %k1 %k2 keepfloat key key_pos kinvariant kt label label_alignment'
    + ' label_orientation labels lassociative lbfgs_ncorrections lbfgs_nfeval_max'
    + ' leftjust legend letrat let_rule_packages lfg lg lhospitallim limsubst linear'
    + ' linear_solver linechar linel|10 linenum line_type linewidth line_width linsolve_params'
    + ' linsolvewarn lispdisp listarith listconstvars listdummyvars lmxchar load_pathname'
    + ' loadprint logabs logarc logcb logconcoeffp logexpand lognegint logsimp logx'
    + ' logx_secondary logy logy_secondary logz lriem m1pbranch macroexpansion macros'
    + ' mainvar manual_demo maperror mapprint matrix_element_add matrix_element_mult'
    + ' matrix_element_transpose maxapplydepth maxapplyheight maxima_tempdir|10 maxima_userdir|10'
    + ' maxnegex MAX_ORD maxposex maxpsifracdenom maxpsifracnum maxpsinegint maxpsiposint'
    + ' maxtayorder mesh_lines_color method mod_big_prime mode_check_errorp'
    + ' mode_checkp mode_check_warnp mod_test mod_threshold modular_linear_solver'
    + ' modulus multiplicative multiplicities myoptions nary negdistrib negsumdispflag'
    + ' newline newtonepsilon newtonmaxiter nextlayerfactor niceindicespref nm nmc'
    + ' noeval nolabels nonegative_lp noninteger nonscalar noun noundisp nouns np'
    + ' npi nticks ntrig numer numer_pbranch obase odd oddfun opacity opproperties'
    + ' opsubst optimprefix optionset orientation origin orthopoly_returns_intervals'
    + ' outative outchar packagefile palette partswitch pdf_file pfeformat phiresolution'
    + ' %piargs piece pivot_count_sx pivot_max_sx plot_format plot_options plot_realpart'
    + ' png_file pochhammer_max_index points pointsize point_size points_joined point_type'
    + ' poislim poisson poly_coefficient_ring poly_elimination_order polyfactor poly_grobner_algorithm'
    + ' poly_grobner_debug poly_monomial_order poly_primary_elimination_order poly_return_term_list'
    + ' poly_secondary_elimination_order poly_top_reduction_only posfun position'
    + ' powerdisp pred prederror primep_number_of_tests product_use_gamma program'
    + ' programmode promote_float_to_bigfloat prompt proportional_axes props psexpand'
    + ' ps_file radexpand radius radsubstflag rassociative ratalgdenom ratchristof'
    + ' ratdenomdivide rateinstein ratepsilon ratfac rational ratmx ratprint ratriemann'
    + ' ratsimpexpons ratvarswitch ratweights ratweyl ratwtlvl real realonly redraw'
    + ' refcheck resolution restart resultant ric riem rmxchar %rnum_list rombergabs'
    + ' rombergit rombergmin rombergtol rootsconmode rootsepsilon run_viewer same_xy'
    + ' same_xyz savedef savefactors scalar scalarmatrixp scale scale_lp setcheck'
    + ' setcheckbreak setval show_edge_color show_edges show_edge_type show_edge_width'
    + ' show_id show_label showtime show_vertex_color show_vertex_size show_vertex_type'
    + ' show_vertices show_weight simp simplified_output simplify_products simpproduct'
    + ' simpsum sinnpiflag solvedecomposes solveexplicit solvefactors solvenullwarn'
    + ' solveradcan solvetrigwarn space sparse sphere spring_embedding_depth sqrtdispflag'
    + ' stardisp startphi starttheta stats_numer stringdisp structures style sublis_apply_lambda'
    + ' subnumsimp sumexpand sumsplitfact surface surface_hide svg_file symmetric'
    + ' tab taylordepth taylor_logexpand taylor_order_coefficients taylor_truncate_polynomials'
    + ' tensorkill terminal testsuite_files thetaresolution timer_devalue title tlimswitch'
    + ' tr track transcompile transform transform_xy translate_fast_arrays transparent'
    + ' transrun tr_array_as_ref tr_bound_function_applyp tr_file_tty_messagesp tr_float_can_branch_complex'
    + ' tr_function_call_default trigexpandplus trigexpandtimes triginverses trigsign'
    + ' trivial_solutions tr_numer tr_optimize_max_loop tr_semicompile tr_state_vars'
    + ' tr_warn_bad_function_calls tr_warn_fexpr tr_warn_meval tr_warn_mode'
    + ' tr_warn_undeclared tr_warn_undefined_variable tstep ttyoff tube_extremes'
    + ' ufg ug %unitexpand unit_vectors uric uriem use_fast_arrays user_preamble'
    + ' usersetunits values vect_cross verbose vertex_color vertex_coloring vertex_partition'
    + ' vertex_size vertex_type view warnings weyl width windowname windowtitle wired_surface'
    + ' wireframe xaxis xaxis_color xaxis_secondary xaxis_type xaxis_width xlabel'
    + ' xlabel_secondary xlength xrange xrange_secondary xtics xtics_axis xtics_rotate'
    + ' xtics_rotate_secondary xtics_secondary xtics_secondary_axis xu_grid x_voxel'
    + ' xy_file xyplane xy_scale yaxis yaxis_color yaxis_secondary yaxis_type yaxis_width'
    + ' ylabel ylabel_secondary ylength yrange yrange_secondary ytics ytics_axis'
    + ' ytics_rotate ytics_rotate_secondary ytics_secondary ytics_secondary_axis'
    + ' yv_grid y_voxel yx_ratio zaxis zaxis_color zaxis_type zaxis_width zeroa zerob'
    + ' zerobern zeta%pi zlabel zlabel_rotate zlength zmin zn_primroot_limit zn_primroot_pretest';
  const SYMBOLS = '_ __ %|0 %%|0';

  return {
    name: 'Maxima',
    keywords: {
      $pattern: '[A-Za-z_%][0-9A-Za-z_%]*',
      keyword: KEYWORDS,
      literal: LITERALS,
      built_in: BUILTIN_FUNCTIONS,
      symbol: SYMBOLS
    },
    contains: [
      {
        className: 'comment',
        begin: '/\\*',
        end: '\\*/',
        contains: [ 'self' ]
      },
      hljs.QUOTE_STRING_MODE,
      {
        className: 'number',
        relevance: 0,
        variants: [
          {
            // float number w/ exponent
            // hmm, I wonder if we ought to include other exponent markers?
            begin: '\\b(\\d+|\\d+\\.|\\.\\d+|\\d+\\.\\d+)[Ee][-+]?\\d+\\b' },
          {
            // bigfloat number
            begin: '\\b(\\d+|\\d+\\.|\\.\\d+|\\d+\\.\\d+)[Bb][-+]?\\d+\\b',
            relevance: 10
          },
          {
            // float number w/out exponent
            // Doesn't seem to recognize floats which start with '.'
            begin: '\\b(\\.\\d+|\\d+\\.\\d+)\\b' },
          {
            // integer in base up to 36
            // Doesn't seem to recognize integers which end with '.'
            begin: '\\b(\\d+|0[0-9A-Za-z]+)\\.?\\b' }
        ]
      }
    ],
    illegal: /@/
  };
}

module.exports = maxima;
