/*
Language: SQF
Author: Søren Enevoldsen <senevoldsen90@gmail.com>
Contributors: Marvin Saignat <contact@zgmrvn.com>, Dedmen Miller <dedmen@dedmen.de>, Leopard20
Description: Scripting language for the Arma game series
Website: https://community.bistudio.com/wiki/SQF_syntax
Category: scripting
Last update: 07.01.2023, Arma 3 v2.11
*/

/*
////////////////////////////////////////////////////////////////////////////////////////////
  * Author: Leopard20
  
  * Description:
  This script can be used to dump all commands to the clipboard.
  Make sure you're using the Diag EXE to dump all of the commands.
  
  * How to use:
  Simply replace the _KEYWORDS and _LITERAL arrays with the one from this sqf.js file.
  Execute the script from the debug console.
  All commands will be copied to the clipboard.
////////////////////////////////////////////////////////////////////////////////////////////
_KEYWORDS = ['if'];                                                //Array of all KEYWORDS
_LITERALS = ['west'];                                              //Array of all LITERALS
_allCommands = createHashMap;
{
  _type = _x select [0,1];
  if (_type != "t") then {
    _command_lowercase = ((_x select [2]) splitString " ")#(((["n", "u", "b"] find _type) - 1) max 0);
    _command_uppercase = supportInfo ("i:" + _command_lowercase) # 0 # 2;
    _allCommands set [_command_lowercase, _command_uppercase];
  };
} forEach supportInfo "";
_allCommands = _allCommands toArray false;
_allCommands sort true;                                            //sort by lowercase
_allCommands = ((_allCommands apply {_x#1}) -_KEYWORDS)-_LITERALS; //remove KEYWORDS and LITERALS
copyToClipboard (str (_allCommands select {_x regexMatch "\w+"}) regexReplace ["""", "'"] regexReplace [",", ",\n"]);
*/

function sqf(hljs) {
  // In SQF, a local variable starts with _
  const VARIABLE = {
    className: 'variable',
    begin: /\b_+[a-zA-Z]\w*/
  };

  // In SQF, a function should fit myTag_fnc_myFunction pattern
  // https://community.bistudio.com/wiki/Functions_Library_(Arma_3)#Adding_a_Function
  const FUNCTION = {
    className: 'title',
    begin: /[a-zA-Z][a-zA-Z_0-9]*_fnc_[a-zA-Z_0-9]+/
  };

  // In SQF strings, quotes matching the start are escaped by adding a consecutive.
  // Example of single escaped quotes: " "" " and  ' '' '.
  const STRINGS = {
    className: 'string',
    variants: [
      {
        begin: '"',
        end: '"',
        contains: [
          {
            begin: '""',
            relevance: 0
          }
        ]
      },
      {
        begin: '\'',
        end: '\'',
        contains: [
          {
            begin: '\'\'',
            relevance: 0
          }
        ]
      }
    ]
  };

  const KEYWORDS = [
    'break',
    'breakWith',
    'breakOut',
    'breakTo',
    'case',
    'catch',
    'continue',
    'continueWith',
    'default',
    'do',
    'else',
    'exit',
    'exitWith',
    'for',
    'forEach',
    'from',
    'if',
    'local',
    'private',
    'switch',
    'step',
    'then',
    'throw',
    'to',
    'try',
    'waitUntil',
    'while',
    'with'
  ];

  const LITERAL = [
    'blufor',
    'civilian',
    'configNull',
    'controlNull',
    'displayNull',
    'diaryRecordNull',
    'east',
    'endl',
    'false',
    'grpNull',
    'independent',
    'lineBreak',
    'locationNull',
    'nil',
    'objNull',
    'opfor',
    'pi',
    'resistance',
    'scriptNull',
    'sideAmbientLife',
    'sideEmpty',
    'sideEnemy',
    'sideFriendly',
    'sideLogic',
    'sideUnknown',
    'taskNull',
    'teamMemberNull',
    'true',
    'west'
  ];

  const BUILT_IN = [
    'abs',
    'accTime',
    'acos',
    'action',
    'actionIDs',
    'actionKeys',
    'actionKeysEx',
    'actionKeysImages',
    'actionKeysNames',
    'actionKeysNamesArray',
    'actionName',
    'actionParams',
    'activateAddons',
    'activatedAddons',
    'activateKey',
    'activeTitleEffectParams',
    'add3DENConnection',
    'add3DENEventHandler',
    'add3DENLayer',
    'addAction',
    'addBackpack',
    'addBackpackCargo',
    'addBackpackCargoGlobal',
    'addBackpackGlobal',
    'addBinocularItem',
    'addCamShake',
    'addCuratorAddons',
    'addCuratorCameraArea',
    'addCuratorEditableObjects',
    'addCuratorEditingArea',
    'addCuratorPoints',
    'addEditorObject',
    'addEventHandler',
    'addForce',
    'addForceGeneratorRTD',
    'addGoggles',
    'addGroupIcon',
    'addHandgunItem',
    'addHeadgear',
    'addItem',
    'addItemCargo',
    'addItemCargoGlobal',
    'addItemPool',
    'addItemToBackpack',
    'addItemToUniform',
    'addItemToVest',
    'addLiveStats',
    'addMagazine',
    'addMagazineAmmoCargo',
    'addMagazineCargo',
    'addMagazineCargoGlobal',
    'addMagazineGlobal',
    'addMagazinePool',
    'addMagazines',
    'addMagazineTurret',
    'addMenu',
    'addMenuItem',
    'addMissionEventHandler',
    'addMPEventHandler',
    'addMusicEventHandler',
    'addonFiles',
    'addOwnedMine',
    'addPlayerScores',
    'addPrimaryWeaponItem',
    'addPublicVariableEventHandler',
    'addRating',
    'addResources',
    'addScore',
    'addScoreSide',
    'addSecondaryWeaponItem',
    'addSwitchableUnit',
    'addTeamMember',
    'addToRemainsCollector',
    'addTorque',
    'addUniform',
    'addUserActionEventHandler',
    'addVehicle',
    'addVest',
    'addWaypoint',
    'addWeapon',
    'addWeaponCargo',
    'addWeaponCargoGlobal',
    'addWeaponGlobal',
    'addWeaponItem',
    'addWeaponPool',
    'addWeaponTurret',
    'addWeaponWithAttachmentsCargo',
    'addWeaponWithAttachmentsCargoGlobal',
    'admin',
    'agent',
    'agents',
    'AGLToASL',
    'aimedAtTarget',
    'aimPos',
    'airDensityCurveRTD',
    'airDensityRTD',
    'airplaneThrottle',
    'airportSide',
    'AISFinishHeal',
    'alive',
    'all3DENEntities',
    'allActiveTitleEffects',
    'allAddonsInfo',
    'allAirports',
    'allControls',
    'allCurators',
    'allCutLayers',
    'allDead',
    'allDeadMen',
    'allDiaryRecords',
    'allDiarySubjects',
    'allDisplays',
    'allEnv3DSoundSources',
    'allGroups',
    'allLODs',
    'allMapMarkers',
    'allMines',
    'allMissionObjects',
    'allObjects',
    'allow3DMode',
    'allowCrewInImmobile',
    'allowCuratorLogicIgnoreAreas',
    'allowDamage',
    'allowDammage',
    'allowedService',
    'allowFileOperations',
    'allowFleeing',
    'allowGetIn',
    'allowService',
    'allowSprint',
    'allPlayers',
    'allSimpleObjects',
    'allSites',
    'allTurrets',
    'allUnits',
    'allUnitsUAV',
    'allUsers',
    'allVariables',
    'ambientTemperature',
    'ammo',
    'ammoOnPylon',
    'and',
    'animate',
    'animateBay',
    'animateDoor',
    'animatePylon',
    'animateSource',
    'animationNames',
    'animationPhase',
    'animationSourcePhase',
    'animationState',
    'apertureParams',
    'append',
    'apply',
    'armoryPoints',
    'arrayIntersect',
    'asin',
    'ASLToAGL',
    'ASLToATL',
    'assert',
    'assignAsCargo',
    'assignAsCargoIndex',
    'assignAsCommander',
    'assignAsDriver',
    'assignAsGunner',
    'assignAsTurret',
    'assignCurator',
    'assignedCargo',
    'assignedCommander',
    'assignedDriver',
    'assignedGroup',
    'assignedGunner',
    'assignedItems',
    'assignedTarget',
    'assignedTeam',
    'assignedVehicle',
    'assignedVehicleRole',
    'assignedVehicles',
    'assignItem',
    'assignTeam',
    'assignToAirport',
    'atan',
    'atan2',
    'atg',
    'ATLToASL',
    'attachedObject',
    'attachedObjects',
    'attachedTo',
    'attachObject',
    'attachTo',
    'attackEnabled',
    'awake',
    'backpack',
    'backpackCargo',
    'backpackContainer',
    'backpackItems',
    'backpackMagazines',
    'backpackSpaceFor',
    'behaviour',
    'benchmark',
    'bezierInterpolation',
    'binocular',
    'binocularItems',
    'binocularMagazine',
    'boundingBox',
    'boundingBoxReal',
    'boundingCenter',
    'brakesDisabled',
    'briefingName',
    'buildingExit',
    'buildingPos',
    'buldozer_EnableRoadDiag',
    'buldozer_IsEnabledRoadDiag',
    'buldozer_LoadNewRoads',
    'buldozer_reloadOperMap',
    'buttonAction',
    'buttonSetAction',
    'cadetMode',
    'calculatePath',
    'calculatePlayerVisibilityByFriendly',
    'call',
    'callExtension',
    'camCommand',
    'camCommit',
    'camCommitPrepared',
    'camCommitted',
    'camConstuctionSetParams',
    'camCreate',
    'camDestroy',
    'cameraEffect',
    'cameraEffectEnableHUD',
    'cameraInterest',
    'cameraOn',
    'cameraView',
    'campaignConfigFile',
    'camPreload',
    'camPreloaded',
    'camPrepareBank',
    'camPrepareDir',
    'camPrepareDive',
    'camPrepareFocus',
    'camPrepareFov',
    'camPrepareFovRange',
    'camPreparePos',
    'camPrepareRelPos',
    'camPrepareTarget',
    'camSetBank',
    'camSetDir',
    'camSetDive',
    'camSetFocus',
    'camSetFov',
    'camSetFovRange',
    'camSetPos',
    'camSetRelPos',
    'camSetTarget',
    'camTarget',
    'camUseNVG',
    'canAdd',
    'canAddItemToBackpack',
    'canAddItemToUniform',
    'canAddItemToVest',
    'cancelSimpleTaskDestination',
    'canDeployWeapon',
    'canFire',
    'canMove',
    'canSlingLoad',
    'canStand',
    'canSuspend',
    'canTriggerDynamicSimulation',
    'canUnloadInCombat',
    'canVehicleCargo',
    'captive',
    'captiveNum',
    'cbChecked',
    'cbSetChecked',
    'ceil',
    'channelEnabled',
    'cheatsEnabled',
    'checkAIFeature',
    'checkVisibility',
    'className',
    'clear3DENAttribute',
    'clear3DENInventory',
    'clearAllItemsFromBackpack',
    'clearBackpackCargo',
    'clearBackpackCargoGlobal',
    'clearForcesRTD',
    'clearGroupIcons',
    'clearItemCargo',
    'clearItemCargoGlobal',
    'clearItemPool',
    'clearMagazineCargo',
    'clearMagazineCargoGlobal',
    'clearMagazinePool',
    'clearOverlay',
    'clearRadio',
    'clearWeaponCargo',
    'clearWeaponCargoGlobal',
    'clearWeaponPool',
    'clientOwner',
    'closeDialog',
    'closeDisplay',
    'closeOverlay',
    'collapseObjectTree',
    'collect3DENHistory',
    'collectiveRTD',
    'collisionDisabledWith',
    'combatBehaviour',
    'combatMode',
    'commandArtilleryFire',
    'commandChat',
    'commander',
    'commandFire',
    'commandFollow',
    'commandFSM',
    'commandGetOut',
    'commandingMenu',
    'commandMove',
    'commandRadio',
    'commandStop',
    'commandSuppressiveFire',
    'commandTarget',
    'commandWatch',
    'comment',
    'commitOverlay',
    'compatibleItems',
    'compatibleMagazines',
    'compile',
    'compileFinal',
    'compileScript',
    'completedFSM',
    'composeText',
    'configClasses',
    'configFile',
    'configHierarchy',
    'configName',
    'configOf',
    'configProperties',
    'configSourceAddonList',
    'configSourceMod',
    'configSourceModList',
    'confirmSensorTarget',
    'connectTerminalToUAV',
    'connectToServer',
    'controlsGroupCtrl',
    'conversationDisabled',
    'copyFromClipboard',
    'copyToClipboard',
    'copyWaypoints',
    'cos',
    'count',
    'countEnemy',
    'countFriendly',
    'countSide',
    'countType',
    'countUnknown',
    'create3DENComposition',
    'create3DENEntity',
    'createAgent',
    'createCenter',
    'createDialog',
    'createDiaryLink',
    'createDiaryRecord',
    'createDiarySubject',
    'createDisplay',
    'createGearDialog',
    'createGroup',
    'createGuardedPoint',
    'createHashMap',
    'createHashMapFromArray',
    'createLocation',
    'createMarker',
    'createMarkerLocal',
    'createMenu',
    'createMine',
    'createMissionDisplay',
    'createMPCampaignDisplay',
    'createSimpleObject',
    'createSimpleTask',
    'createSite',
    'createSoundSource',
    'createTask',
    'createTeam',
    'createTrigger',
    'createUnit',
    'createVehicle',
    'createVehicleCrew',
    'createVehicleLocal',
    'crew',
    'ctAddHeader',
    'ctAddRow',
    'ctClear',
    'ctCurSel',
    'ctData',
    'ctFindHeaderRows',
    'ctFindRowHeader',
    'ctHeaderControls',
    'ctHeaderCount',
    'ctRemoveHeaders',
    'ctRemoveRows',
    'ctrlActivate',
    'ctrlAddEventHandler',
    'ctrlAngle',
    'ctrlAnimateModel',
    'ctrlAnimationPhaseModel',
    'ctrlAt',
    'ctrlAutoScrollDelay',
    'ctrlAutoScrollRewind',
    'ctrlAutoScrollSpeed',
    'ctrlBackgroundColor',
    'ctrlChecked',
    'ctrlClassName',
    'ctrlCommit',
    'ctrlCommitted',
    'ctrlCreate',
    'ctrlDelete',
    'ctrlEnable',
    'ctrlEnabled',
    'ctrlFade',
    'ctrlFontHeight',
    'ctrlForegroundColor',
    'ctrlHTMLLoaded',
    'ctrlIDC',
    'ctrlIDD',
    'ctrlMapAnimAdd',
    'ctrlMapAnimClear',
    'ctrlMapAnimCommit',
    'ctrlMapAnimDone',
    'ctrlMapCursor',
    'ctrlMapMouseOver',
    'ctrlMapPosition',
    'ctrlMapScale',
    'ctrlMapScreenToWorld',
    'ctrlMapSetPosition',
    'ctrlMapWorldToScreen',
    'ctrlModel',
    'ctrlModelDirAndUp',
    'ctrlModelScale',
    'ctrlMousePosition',
    'ctrlParent',
    'ctrlParentControlsGroup',
    'ctrlPosition',
    'ctrlRemoveAllEventHandlers',
    'ctrlRemoveEventHandler',
    'ctrlScale',
    'ctrlScrollValues',
    'ctrlSetActiveColor',
    'ctrlSetAngle',
    'ctrlSetAutoScrollDelay',
    'ctrlSetAutoScrollRewind',
    'ctrlSetAutoScrollSpeed',
    'ctrlSetBackgroundColor',
    'ctrlSetChecked',
    'ctrlSetDisabledColor',
    'ctrlSetEventHandler',
    'ctrlSetFade',
    'ctrlSetFocus',
    'ctrlSetFont',
    'ctrlSetFontH1',
    'ctrlSetFontH1B',
    'ctrlSetFontH2',
    'ctrlSetFontH2B',
    'ctrlSetFontH3',
    'ctrlSetFontH3B',
    'ctrlSetFontH4',
    'ctrlSetFontH4B',
    'ctrlSetFontH5',
    'ctrlSetFontH5B',
    'ctrlSetFontH6',
    'ctrlSetFontH6B',
    'ctrlSetFontHeight',
    'ctrlSetFontHeightH1',
    'ctrlSetFontHeightH2',
    'ctrlSetFontHeightH3',
    'ctrlSetFontHeightH4',
    'ctrlSetFontHeightH5',
    'ctrlSetFontHeightH6',
    'ctrlSetFontHeightSecondary',
    'ctrlSetFontP',
    'ctrlSetFontPB',
    'ctrlSetFontSecondary',
    'ctrlSetForegroundColor',
    'ctrlSetModel',
    'ctrlSetModelDirAndUp',
    'ctrlSetModelScale',
    'ctrlSetMousePosition',
    'ctrlSetPixelPrecision',
    'ctrlSetPosition',
    'ctrlSetPositionH',
    'ctrlSetPositionW',
    'ctrlSetPositionX',
    'ctrlSetPositionY',
    'ctrlSetScale',
    'ctrlSetScrollValues',
    'ctrlSetShadow',
    'ctrlSetStructuredText',
    'ctrlSetText',
    'ctrlSetTextColor',
    'ctrlSetTextColorSecondary',
    'ctrlSetTextSecondary',
    'ctrlSetTextSelection',
    'ctrlSetTooltip',
    'ctrlSetTooltipColorBox',
    'ctrlSetTooltipColorShade',
    'ctrlSetTooltipColorText',
    'ctrlSetTooltipMaxWidth',
    'ctrlSetURL',
    'ctrlSetURLOverlayMode',
    'ctrlShadow',
    'ctrlShow',
    'ctrlShown',
    'ctrlStyle',
    'ctrlText',
    'ctrlTextColor',
    'ctrlTextHeight',
    'ctrlTextSecondary',
    'ctrlTextSelection',
    'ctrlTextWidth',
    'ctrlTooltip',
    'ctrlType',
    'ctrlURL',
    'ctrlURLOverlayMode',
    'ctrlVisible',
    'ctRowControls',
    'ctRowCount',
    'ctSetCurSel',
    'ctSetData',
    'ctSetHeaderTemplate',
    'ctSetRowTemplate',
    'ctSetValue',
    'ctValue',
    'curatorAddons',
    'curatorCamera',
    'curatorCameraArea',
    'curatorCameraAreaCeiling',
    'curatorCoef',
    'curatorEditableObjects',
    'curatorEditingArea',
    'curatorEditingAreaType',
    'curatorMouseOver',
    'curatorPoints',
    'curatorRegisteredObjects',
    'curatorSelected',
    'curatorWaypointCost',
    'current3DENOperation',
    'currentChannel',
    'currentCommand',
    'currentMagazine',
    'currentMagazineDetail',
    'currentMagazineDetailTurret',
    'currentMagazineTurret',
    'currentMuzzle',
    'currentNamespace',
    'currentPilot',
    'currentTask',
    'currentTasks',
    'currentThrowable',
    'currentVisionMode',
    'currentWaypoint',
    'currentWeapon',
    'currentWeaponMode',
    'currentWeaponTurret',
    'currentZeroing',
    'cursorObject',
    'cursorTarget',
    'customChat',
    'customRadio',
    'customWaypointPosition',
    'cutFadeOut',
    'cutObj',
    'cutRsc',
    'cutText',
    'damage',
    'date',
    'dateToNumber',
    'dayTime',
    'deActivateKey',
    'debriefingText',
    'debugFSM',
    'debugLog',
    'decayGraphValues',
    'deg',
    'delete3DENEntities',
    'deleteAt',
    'deleteCenter',
    'deleteCollection',
    'deleteEditorObject',
    'deleteGroup',
    'deleteGroupWhenEmpty',
    'deleteIdentity',
    'deleteLocation',
    'deleteMarker',
    'deleteMarkerLocal',
    'deleteRange',
    'deleteResources',
    'deleteSite',
    'deleteStatus',
    'deleteTeam',
    'deleteVehicle',
    'deleteVehicleCrew',
    'deleteWaypoint',
    'detach',
    'detectedMines',
    'diag_activeMissionFSMs',
    'diag_activeScripts',
    'diag_activeSQFScripts',
    'diag_activeSQSScripts',
    'diag_allMissionEventHandlers',
    'diag_captureFrame',
    'diag_captureFrameToFile',
    'diag_captureSlowFrame',
    'diag_codePerformance',
    'diag_deltaTime',
    'diag_drawmode',
    'diag_dumpCalltraceToLog',
    'diag_dumpScriptAssembly',
    'diag_dumpTerrainSynth',
    'diag_dynamicSimulationEnd',
    'diag_enable',
    'diag_enabled',
    'diag_exportConfig',
    'diag_exportTerrainSVG',
    'diag_fps',
    'diag_fpsmin',
    'diag_frameno',
    'diag_getTerrainSegmentOffset',
    'diag_lightNewLoad',
    'diag_list',
    'diag_localized',
    'diag_log',
    'diag_logSlowFrame',
    'diag_mergeConfigFile',
    'diag_recordTurretLimits',
    'diag_resetFSM',
    'diag_resetshapes',
    'diag_scope',
    'diag_setLightNew',
    'diag_stacktrace',
    'diag_tickTime',
    'diag_toggle',
    'dialog',
    'diarySubjectExists',
    'didJIP',
    'didJIPOwner',
    'difficulty',
    'difficultyEnabled',
    'difficultyEnabledRTD',
    'difficultyOption',
    'direction',
    'directionStabilizationEnabled',
    'directSay',
    'disableAI',
    'disableBrakes',
    'disableCollisionWith',
    'disableConversation',
    'disableDebriefingStats',
    'disableMapIndicators',
    'disableNVGEquipment',
    'disableRemoteSensors',
    'disableSerialization',
    'disableTIEquipment',
    'disableUAVConnectability',
    'disableUserInput',
    'displayAddEventHandler',
    'displayChild',
    'displayCtrl',
    'displayParent',
    'displayRemoveAllEventHandlers',
    'displayRemoveEventHandler',
    'displaySetEventHandler',
    'displayUniqueName',
    'displayUpdate',
    'dissolveTeam',
    'distance',
    'distance2D',
    'distanceSqr',
    'distributionRegion',
    'do3DENAction',
    'doArtilleryFire',
    'doFire',
    'doFollow',
    'doFSM',
    'doGetOut',
    'doMove',
    'doorPhase',
    'doStop',
    'doSuppressiveFire',
    'doTarget',
    'doWatch',
    'drawArrow',
    'drawEllipse',
    'drawIcon',
    'drawIcon3D',
    'drawLaser',
    'drawLine',
    'drawLine3D',
    'drawLink',
    'drawLocation',
    'drawPolygon',
    'drawRectangle',
    'drawTriangle',
    'driver',
    'drop',
    'dynamicSimulationDistance',
    'dynamicSimulationDistanceCoef',
    'dynamicSimulationEnabled',
    'dynamicSimulationSystemEnabled',
    'echo',
    'edit3DENMissionAttributes',
    'editObject',
    'editorSetEventHandler',
    'effectiveCommander',
    'elevatePeriscope',
    'emptyPositions',
    'enableAI',
    'enableAIFeature',
    'enableAimPrecision',
    'enableAttack',
    'enableAudioFeature',
    'enableAutoStartUpRTD',
    'enableAutoTrimRTD',
    'enableCamShake',
    'enableCaustics',
    'enableChannel',
    'enableCollisionWith',
    'enableCopilot',
    'enableDebriefingStats',
    'enableDiagLegend',
    'enableDirectionStabilization',
    'enableDynamicSimulation',
    'enableDynamicSimulationSystem',
    'enableEndDialog',
    'enableEngineArtillery',
    'enableEnvironment',
    'enableFatigue',
    'enableGunLights',
    'enableInfoPanelComponent',
    'enableIRLasers',
    'enableMimics',
    'enablePersonTurret',
    'enableRadio',
    'enableReload',
    'enableRopeAttach',
    'enableSatNormalOnDetail',
    'enableSaving',
    'enableSentences',
    'enableSimulation',
    'enableSimulationGlobal',
    'enableStamina',
    'enableStressDamage',
    'enableTeamSwitch',
    'enableTraffic',
    'enableUAVConnectability',
    'enableUAVWaypoints',
    'enableVehicleCargo',
    'enableVehicleSensor',
    'enableWeaponDisassembly',
    'endLoadingScreen',
    'endMission',
    'engineOn',
    'enginesIsOnRTD',
    'enginesPowerRTD',
    'enginesRpmRTD',
    'enginesTorqueRTD',
    'entities',
    'environmentEnabled',
    'environmentVolume',
    'equipmentDisabled',
    'estimatedEndServerTime',
    'estimatedTimeLeft',
    'evalObjectArgument',
    'everyBackpack',
    'everyContainer',
    'exec',
    'execEditorScript',
    'execFSM',
    'execVM',
    'exp',
    'expectedDestination',
    'exportJIPMessages',
    'eyeDirection',
    'eyePos',
    'face',
    'faction',
    'fadeEnvironment',
    'fadeMusic',
    'fadeRadio',
    'fadeSound',
    'fadeSpeech',
    'failMission',
    'fileExists',
    'fillWeaponsFromPool',
    'find',
    'findAny',
    'findCover',
    'findDisplay',
    'findEditorObject',
    'findEmptyPosition',
    'findEmptyPositionReady',
    'findIf',
    'findNearestEnemy',
    'finishMissionInit',
    'finite',
    'fire',
    'fireAtTarget',
    'firstBackpack',
    'flag',
    'flagAnimationPhase',
    'flagOwner',
    'flagSide',
    'flagTexture',
    'flatten',
    'fleeing',
    'floor',
    'flyInHeight',
    'flyInHeightASL',
    'focusedCtrl',
    'fog',
    'fogForecast',
    'fogParams',
    'forceAddUniform',
    'forceAtPositionRTD',
    'forceCadetDifficulty',
    'forcedMap',
    'forceEnd',
    'forceFlagTexture',
    'forceFollowRoad',
    'forceGeneratorRTD',
    'forceMap',
    'forceRespawn',
    'forceSpeed',
    'forceUnicode',
    'forceWalk',
    'forceWeaponFire',
    'forceWeatherChange',
    'forEachMember',
    'forEachMemberAgent',
    'forEachMemberTeam',
    'forgetTarget',
    'format',
    'formation',
    'formationDirection',
    'formationLeader',
    'formationMembers',
    'formationPosition',
    'formationTask',
    'formatText',
    'formLeader',
    'freeExtension',
    'freeLook',
    'fromEditor',
    'fuel',
    'fullCrew',
    'gearIDCAmmoCount',
    'gearSlotAmmoCount',
    'gearSlotData',
    'gestureState',
    'get',
    'get3DENActionState',
    'get3DENAttribute',
    'get3DENCamera',
    'get3DENConnections',
    'get3DENEntity',
    'get3DENEntityID',
    'get3DENGrid',
    'get3DENIconsVisible',
    'get3DENLayerEntities',
    'get3DENLinesVisible',
    'get3DENMissionAttribute',
    'get3DENMouseOver',
    'get3DENSelected',
    'getAimingCoef',
    'getAllEnv3DSoundControllers',
    'getAllEnvSoundControllers',
    'getAllHitPointsDamage',
    'getAllOwnedMines',
    'getAllPylonsInfo',
    'getAllSoundControllers',
    'getAllUnitTraits',
    'getAmmoCargo',
    'getAnimAimPrecision',
    'getAnimSpeedCoef',
    'getArray',
    'getArtilleryAmmo',
    'getArtilleryComputerSettings',
    'getArtilleryETA',
    'getAssetDLCInfo',
    'getAssignedCuratorLogic',
    'getAssignedCuratorUnit',
    'getAttackTarget',
    'getAudioOptionVolumes',
    'getBackpackCargo',
    'getBleedingRemaining',
    'getBurningValue',
    'getCalculatePlayerVisibilityByFriendly',
    'getCameraViewDirection',
    'getCargoIndex',
    'getCenterOfMass',
    'getClientState',
    'getClientStateNumber',
    'getCompatiblePylonMagazines',
    'getConnectedUAV',
    'getConnectedUAVUnit',
    'getContainerMaxLoad',
    'getCorpse',
    'getCruiseControl',
    'getCursorObjectParams',
    'getCustomAimCoef',
    'getCustomSoundController',
    'getCustomSoundControllerCount',
    'getDammage',
    'getDebriefingText',
    'getDescription',
    'getDir',
    'getDirVisual',
    'getDiverState',
    'getDLCAssetsUsage',
    'getDLCAssetsUsageByName',
    'getDLCs',
    'getDLCUsageTime',
    'getEditorCamera',
    'getEditorMode',
    'getEditorObjectScope',
    'getElevationOffset',
    'getEngineTargetRPMRTD',
    'getEnv3DSoundController',
    'getEnvSoundController',
    'getEventHandlerInfo',
    'getFatigue',
    'getFieldManualStartPage',
    'getForcedFlagTexture',
    'getForcedSpeed',
    'getFriend',
    'getFSMVariable',
    'getFuelCargo',
    'getGraphValues',
    'getGroupIcon',
    'getGroupIconParams',
    'getGroupIcons',
    'getHideFrom',
    'getHit',
    'getHitIndex',
    'getHitPointDamage',
    'getItemCargo',
    'getLighting',
    'getLightingAt',
    'getLoadedModsInfo',
    'getMagazineCargo',
    'getMarkerColor',
    'getMarkerPos',
    'getMarkerSize',
    'getMarkerType',
    'getMass',
    'getMissionConfig',
    'getMissionConfigValue',
    'getMissionDLCs',
    'getMissionLayerEntities',
    'getMissionLayers',
    'getMissionPath',
    'getModelInfo',
    'getMousePosition',
    'getMusicPlayedTime',
    'getNumber',
    'getObjectArgument',
    'getObjectChildren',
    'getObjectDLC',
    'getObjectFOV',
    'getObjectID',
    'getObjectMaterials',
    'getObjectProxy',
    'getObjectScale',
    'getObjectTextures',
    'getObjectType',
    'getObjectViewDistance',
    'getOpticsMode',
    'getOrDefault',
    'getOrDefaultCall',
    'getOxygenRemaining',
    'getPersonUsedDLCs',
    'getPilotCameraDirection',
    'getPilotCameraPosition',
    'getPilotCameraRotation',
    'getPilotCameraTarget',
    'getPiPViewDistance',
    'getPlateNumber',
    'getPlayerChannel',
    'getPlayerID',
    'getPlayerScores',
    'getPlayerUID',
    'getPlayerVoNVolume',
    'getPos',
    'getPosASL',
    'getPosASLVisual',
    'getPosASLW',
    'getPosATL',
    'getPosATLVisual',
    'getPosVisual',
    'getPosWorld',
    'getPosWorldVisual',
    'getPylonMagazines',
    'getRelDir',
    'getRelPos',
    'getRemoteSensorsDisabled',
    'getRepairCargo',
    'getResolution',
    'getRoadInfo',
    'getRotorBrakeRTD',
    'getSensorTargets',
    'getSensorThreats',
    'getShadowDistance',
    'getShotParents',
    'getSlingLoad',
    'getSoundController',
    'getSoundControllerResult',
    'getSpeed',
    'getStamina',
    'getStatValue',
    'getSteamFriendsServers',
    'getSubtitleOptions',
    'getSuppression',
    'getTerrainGrid',
    'getTerrainHeight',
    'getTerrainHeightASL',
    'getTerrainInfo',
    'getText',
    'getTextRaw',
    'getTextureInfo',
    'getTextWidth',
    'getTiParameters',
    'getTotalDLCUsageTime',
    'getTrimOffsetRTD',
    'getTurretLimits',
    'getTurretOpticsMode',
    'getUnitFreefallInfo',
    'getUnitLoadout',
    'getUnitTrait',
    'getUnloadInCombat',
    'getUserInfo',
    'getUserMFDText',
    'getUserMFDValue',
    'getVariable',
    'getVehicleCargo',
    'getVehicleTiPars',
    'getWeaponCargo',
    'getWeaponSway',
    'getWingsOrientationRTD',
    'getWingsPositionRTD',
    'getWPPos',
    'glanceAt',
    'globalChat',
    'globalRadio',
    'goggles',
    'goto',
    'group',
    'groupChat',
    'groupFromNetId',
    'groupIconSelectable',
    'groupIconsVisible',
    'groupID',
    'groupOwner',
    'groupRadio',
    'groups',
    'groupSelectedUnits',
    'groupSelectUnit',
    'gunner',
    'gusts',
    'halt',
    'handgunItems',
    'handgunMagazine',
    'handgunWeapon',
    'handsHit',
    'hashValue',
    'hasInterface',
    'hasPilotCamera',
    'hasWeapon',
    'hcAllGroups',
    'hcGroupParams',
    'hcLeader',
    'hcRemoveAllGroups',
    'hcRemoveGroup',
    'hcSelected',
    'hcSelectGroup',
    'hcSetGroup',
    'hcShowBar',
    'hcShownBar',
    'headgear',
    'hideBody',
    'hideObject',
    'hideObjectGlobal',
    'hideSelection',
    'hint',
    'hintC',
    'hintCadet',
    'hintSilent',
    'hmd',
    'hostMission',
    'htmlLoad',
    'HUDMovementLevels',
    'humidity',
    'image',
    'importAllGroups',
    'importance',
    'in',
    'inArea',
    'inAreaArray',
    'incapacitatedState',
    'inflame',
    'inflamed',
    'infoPanel',
    'infoPanelComponentEnabled',
    'infoPanelComponents',
    'infoPanels',
    'inGameUISetEventHandler',
    'inheritsFrom',
    'initAmbientLife',
    'inPolygon',
    'inputAction',
    'inputController',
    'inputMouse',
    'inRangeOfArtillery',
    'insert',
    'insertEditorObject',
    'intersect',
    'is3DEN',
    'is3DENMultiplayer',
    'is3DENPreview',
    'isAbleToBreathe',
    'isActionMenuVisible',
    'isAgent',
    'isAimPrecisionEnabled',
    'isAllowedCrewInImmobile',
    'isArray',
    'isAutoHoverOn',
    'isAutonomous',
    'isAutoStartUpEnabledRTD',
    'isAutotest',
    'isAutoTrimOnRTD',
    'isAwake',
    'isBleeding',
    'isBurning',
    'isClass',
    'isCollisionLightOn',
    'isCopilotEnabled',
    'isDamageAllowed',
    'isDedicated',
    'isDLCAvailable',
    'isEngineOn',
    'isEqualRef',
    'isEqualTo',
    'isEqualType',
    'isEqualTypeAll',
    'isEqualTypeAny',
    'isEqualTypeArray',
    'isEqualTypeParams',
    'isFilePatchingEnabled',
    'isFinal',
    'isFlashlightOn',
    'isFlatEmpty',
    'isForcedWalk',
    'isFormationLeader',
    'isGameFocused',
    'isGamePaused',
    'isGroupDeletedWhenEmpty',
    'isHidden',
    'isInRemainsCollector',
    'isInstructorFigureEnabled',
    'isIRLaserOn',
    'isKeyActive',
    'isKindOf',
    'isLaserOn',
    'isLightOn',
    'isLocalized',
    'isManualFire',
    'isMarkedForCollection',
    'isMissionProfileNamespaceLoaded',
    'isMultiplayer',
    'isMultiplayerSolo',
    'isNil',
    'isNotEqualRef',
    'isNotEqualTo',
    'isNull',
    'isNumber',
    'isObjectHidden',
    'isObjectRTD',
    'isOnRoad',
    'isPiPEnabled',
    'isPlayer',
    'isRealTime',
    'isRemoteExecuted',
    'isRemoteExecutedJIP',
    'isSaving',
    'isSensorTargetConfirmed',
    'isServer',
    'isShowing3DIcons',
    'isSimpleObject',
    'isSprintAllowed',
    'isStaminaEnabled',
    'isSteamMission',
    'isSteamOverlayEnabled',
    'isStreamFriendlyUIEnabled',
    'isStressDamageEnabled',
    'isText',
    'isTouchingGround',
    'isTurnedOut',
    'isTutHintsEnabled',
    'isUAVConnectable',
    'isUAVConnected',
    'isUIContext',
    'isUniformAllowed',
    'isVehicleCargo',
    'isVehicleRadarOn',
    'isVehicleSensorEnabled',
    'isWalking',
    'isWeaponDeployed',
    'isWeaponRested',
    'itemCargo',
    'items',
    'itemsWithMagazines',
    'join',
    'joinAs',
    'joinAsSilent',
    'joinSilent',
    'joinString',
    'kbAddDatabase',
    'kbAddDatabaseTargets',
    'kbAddTopic',
    'kbHasTopic',
    'kbReact',
    'kbRemoveTopic',
    'kbTell',
    'kbWasSaid',
    'keyImage',
    'keyName',
    'keys',
    'knowsAbout',
    'land',
    'landAt',
    'landResult',
    'language',
    'laserTarget',
    'lbAdd',
    'lbClear',
    'lbColor',
    'lbColorRight',
    'lbCurSel',
    'lbData',
    'lbDelete',
    'lbIsSelected',
    'lbPicture',
    'lbPictureRight',
    'lbSelection',
    'lbSetColor',
    'lbSetColorRight',
    'lbSetCurSel',
    'lbSetData',
    'lbSetPicture',
    'lbSetPictureColor',
    'lbSetPictureColorDisabled',
    'lbSetPictureColorSelected',
    'lbSetPictureRight',
    'lbSetPictureRightColor',
    'lbSetPictureRightColorDisabled',
    'lbSetPictureRightColorSelected',
    'lbSetSelectColor',
    'lbSetSelectColorRight',
    'lbSetSelected',
    'lbSetText',
    'lbSetTextRight',
    'lbSetTooltip',
    'lbSetValue',
    'lbSize',
    'lbSort',
    'lbSortBy',
    'lbSortByValue',
    'lbText',
    'lbTextRight',
    'lbTooltip',
    'lbValue',
    'leader',
    'leaderboardDeInit',
    'leaderboardGetRows',
    'leaderboardInit',
    'leaderboardRequestRowsFriends',
    'leaderboardRequestRowsGlobal',
    'leaderboardRequestRowsGlobalAroundUser',
    'leaderboardsRequestUploadScore',
    'leaderboardsRequestUploadScoreKeepBest',
    'leaderboardState',
    'leaveVehicle',
    'libraryCredits',
    'libraryDisclaimers',
    'lifeState',
    'lightAttachObject',
    'lightDetachObject',
    'lightIsOn',
    'lightnings',
    'limitSpeed',
    'linearConversion',
    'lineIntersects',
    'lineIntersectsObjs',
    'lineIntersectsSurfaces',
    'lineIntersectsWith',
    'linkItem',
    'list',
    'listObjects',
    'listRemoteTargets',
    'listVehicleSensors',
    'ln',
    'lnbAddArray',
    'lnbAddColumn',
    'lnbAddRow',
    'lnbClear',
    'lnbColor',
    'lnbColorRight',
    'lnbCurSelRow',
    'lnbData',
    'lnbDeleteColumn',
    'lnbDeleteRow',
    'lnbGetColumnsPosition',
    'lnbPicture',
    'lnbPictureRight',
    'lnbSetColor',
    'lnbSetColorRight',
    'lnbSetColumnsPos',
    'lnbSetCurSelRow',
    'lnbSetData',
    'lnbSetPicture',
    'lnbSetPictureColor',
    'lnbSetPictureColorRight',
    'lnbSetPictureColorSelected',
    'lnbSetPictureColorSelectedRight',
    'lnbSetPictureRight',
    'lnbSetText',
    'lnbSetTextRight',
    'lnbSetTooltip',
    'lnbSetValue',
    'lnbSize',
    'lnbSort',
    'lnbSortBy',
    'lnbSortByValue',
    'lnbText',
    'lnbTextRight',
    'lnbValue',
    'load',
    'loadAbs',
    'loadBackpack',
    'loadConfig',
    'loadFile',
    'loadGame',
    'loadIdentity',
    'loadMagazine',
    'loadOverlay',
    'loadStatus',
    'loadUniform',
    'loadVest',
    'localize',
    'localNamespace',
    'locationPosition',
    'lock',
    'lockCameraTo',
    'lockCargo',
    'lockDriver',
    'locked',
    'lockedCameraTo',
    'lockedCargo',
    'lockedDriver',
    'lockedInventory',
    'lockedTurret',
    'lockIdentity',
    'lockInventory',
    'lockTurret',
    'lockWp',
    'log',
    'logEntities',
    'logNetwork',
    'logNetworkTerminate',
    'lookAt',
    'lookAtPos',
    'magazineCargo',
    'magazines',
    'magazinesAllTurrets',
    'magazinesAmmo',
    'magazinesAmmoCargo',
    'magazinesAmmoFull',
    'magazinesDetail',
    'magazinesDetailBackpack',
    'magazinesDetailUniform',
    'magazinesDetailVest',
    'magazinesTurret',
    'magazineTurretAmmo',
    'mapAnimAdd',
    'mapAnimClear',
    'mapAnimCommit',
    'mapAnimDone',
    'mapCenterOnCamera',
    'mapGridPosition',
    'markAsFinishedOnSteam',
    'markerAlpha',
    'markerBrush',
    'markerChannel',
    'markerColor',
    'markerDir',
    'markerPolyline',
    'markerPos',
    'markerShadow',
    'markerShape',
    'markerSize',
    'markerText',
    'markerType',
    'matrixMultiply',
    'matrixTranspose',
    'max',
    'maxLoad',
    'members',
    'menuAction',
    'menuAdd',
    'menuChecked',
    'menuClear',
    'menuCollapse',
    'menuData',
    'menuDelete',
    'menuEnable',
    'menuEnabled',
    'menuExpand',
    'menuHover',
    'menuPicture',
    'menuSetAction',
    'menuSetCheck',
    'menuSetData',
    'menuSetPicture',
    'menuSetShortcut',
    'menuSetText',
    'menuSetURL',
    'menuSetValue',
    'menuShortcut',
    'menuShortcutText',
    'menuSize',
    'menuSort',
    'menuText',
    'menuURL',
    'menuValue',
    'merge',
    'min',
    'mineActive',
    'mineDetectedBy',
    'missileTarget',
    'missileTargetPos',
    'missionConfigFile',
    'missionDifficulty',
    'missionEnd',
    'missionName',
    'missionNameSource',
    'missionNamespace',
    'missionProfileNamespace',
    'missionStart',
    'missionVersion',
    'mod',
    'modelToWorld',
    'modelToWorldVisual',
    'modelToWorldVisualWorld',
    'modelToWorldWorld',
    'modParams',
    'moonIntensity',
    'moonPhase',
    'morale',
    'move',
    'move3DENCamera',
    'moveInAny',
    'moveInCargo',
    'moveInCommander',
    'moveInDriver',
    'moveInGunner',
    'moveInTurret',
    'moveObjectToEnd',
    'moveOut',
    'moveTime',
    'moveTo',
    'moveToCompleted',
    'moveToFailed',
    'musicVolume',
    'name',
    'namedProperties',
    'nameSound',
    'nearEntities',
    'nearestBuilding',
    'nearestLocation',
    'nearestLocations',
    'nearestLocationWithDubbing',
    'nearestMines',
    'nearestObject',
    'nearestObjects',
    'nearestTerrainObjects',
    'nearObjects',
    'nearObjectsReady',
    'nearRoads',
    'nearSupplies',
    'nearTargets',
    'needReload',
    'needService',
    'netId',
    'netObjNull',
    'newOverlay',
    'nextMenuItemIndex',
    'nextWeatherChange',
    'nMenuItems',
    'not',
    'numberOfEnginesRTD',
    'numberToDate',
    'objectCurators',
    'objectFromNetId',
    'objectParent',
    'objStatus',
    'onBriefingGroup',
    'onBriefingNotes',
    'onBriefingPlan',
    'onBriefingTeamSwitch',
    'onCommandModeChanged',
    'onDoubleClick',
    'onEachFrame',
    'onGroupIconClick',
    'onGroupIconOverEnter',
    'onGroupIconOverLeave',
    'onHCGroupSelectionChanged',
    'onMapSingleClick',
    'onPlayerConnected',
    'onPlayerDisconnected',
    'onPreloadFinished',
    'onPreloadStarted',
    'onShowNewObject',
    'onTeamSwitch',
    'openCuratorInterface',
    'openDLCPage',
    'openGPS',
    'openMap',
    'openSteamApp',
    'openYoutubeVideo',
    'or',
    'orderGetIn',
    'overcast',
    'overcastForecast',
    'owner',
    'param',
    'params',
    'parseNumber',
    'parseSimpleArray',
    'parseText',
    'parsingNamespace',
    'particlesQuality',
    'periscopeElevation',
    'pickWeaponPool',
    'pitch',
    'pixelGrid',
    'pixelGridBase',
    'pixelGridNoUIScale',
    'pixelH',
    'pixelW',
    'playableSlotsNumber',
    'playableUnits',
    'playAction',
    'playActionNow',
    'player',
    'playerRespawnTime',
    'playerSide',
    'playersNumber',
    'playGesture',
    'playMission',
    'playMove',
    'playMoveNow',
    'playMusic',
    'playScriptedMission',
    'playSound',
    'playSound3D',
    'playSoundUI',
    'pose',
    'position',
    'positionCameraToWorld',
    'posScreenToWorld',
    'posWorldToScreen',
    'ppEffectAdjust',
    'ppEffectCommit',
    'ppEffectCommitted',
    'ppEffectCreate',
    'ppEffectDestroy',
    'ppEffectEnable',
    'ppEffectEnabled',
    'ppEffectForceInNVG',
    'precision',
    'preloadCamera',
    'preloadObject',
    'preloadSound',
    'preloadTitleObj',
    'preloadTitleRsc',
    'preprocessFile',
    'preprocessFileLineNumbers',
    'primaryWeapon',
    'primaryWeaponItems',
    'primaryWeaponMagazine',
    'priority',
    'processDiaryLink',
    'productVersion',
    'profileName',
    'profileNamespace',
    'profileNameSteam',
    'progressLoadingScreen',
    'progressPosition',
    'progressSetPosition',
    'publicVariable',
    'publicVariableClient',
    'publicVariableServer',
    'pushBack',
    'pushBackUnique',
    'putWeaponPool',
    'queryItemsPool',
    'queryMagazinePool',
    'queryWeaponPool',
    'rad',
    'radioChannelAdd',
    'radioChannelCreate',
    'radioChannelInfo',
    'radioChannelRemove',
    'radioChannelSetCallSign',
    'radioChannelSetLabel',
    'radioEnabled',
    'radioVolume',
    'rain',
    'rainbow',
    'rainParams',
    'random',
    'rank',
    'rankId',
    'rating',
    'rectangular',
    'regexFind',
    'regexMatch',
    'regexReplace',
    'registeredTasks',
    'registerTask',
    'reload',
    'reloadEnabled',
    'remoteControl',
    'remoteExec',
    'remoteExecCall',
    'remoteExecutedOwner',
    'remove3DENConnection',
    'remove3DENEventHandler',
    'remove3DENLayer',
    'removeAction',
    'removeAll3DENEventHandlers',
    'removeAllActions',
    'removeAllAssignedItems',
    'removeAllBinocularItems',
    'removeAllContainers',
    'removeAllCuratorAddons',
    'removeAllCuratorCameraAreas',
    'removeAllCuratorEditingAreas',
    'removeAllEventHandlers',
    'removeAllHandgunItems',
    'removeAllItems',
    'removeAllItemsWithMagazines',
    'removeAllMissionEventHandlers',
    'removeAllMPEventHandlers',
    'removeAllMusicEventHandlers',
    'removeAllOwnedMines',
    'removeAllPrimaryWeaponItems',
    'removeAllSecondaryWeaponItems',
    'removeAllUserActionEventHandlers',
    'removeAllWeapons',
    'removeBackpack',
    'removeBackpackGlobal',
    'removeBinocularItem',
    'removeCuratorAddons',
    'removeCuratorCameraArea',
    'removeCuratorEditableObjects',
    'removeCuratorEditingArea',
    'removeDiaryRecord',
    'removeDiarySubject',
    'removeDrawIcon',
    'removeDrawLinks',
    'removeEventHandler',
    'removeFromRemainsCollector',
    'removeGoggles',
    'removeGroupIcon',
    'removeHandgunItem',
    'removeHeadgear',
    'removeItem',
    'removeItemFromBackpack',
    'removeItemFromUniform',
    'removeItemFromVest',
    'removeItems',
    'removeMagazine',
    'removeMagazineGlobal',
    'removeMagazines',
    'removeMagazinesTurret',
    'removeMagazineTurret',
    'removeMenuItem',
    'removeMissionEventHandler',
    'removeMPEventHandler',
    'removeMusicEventHandler',
    'removeOwnedMine',
    'removePrimaryWeaponItem',
    'removeSecondaryWeaponItem',
    'removeSimpleTask',
    'removeSwitchableUnit',
    'removeTeamMember',
    'removeUniform',
    'removeUserActionEventHandler',
    'removeVest',
    'removeWeapon',
    'removeWeaponAttachmentCargo',
    'removeWeaponCargo',
    'removeWeaponGlobal',
    'removeWeaponTurret',
    'reportRemoteTarget',
    'requiredVersion',
    'resetCamShake',
    'resetSubgroupDirection',
    'resize',
    'resources',
    'respawnVehicle',
    'restartEditorCamera',
    'reveal',
    'revealMine',
    'reverse',
    'reversedMouseY',
    'roadAt',
    'roadsConnectedTo',
    'roleDescription',
    'ropeAttachedObjects',
    'ropeAttachedTo',
    'ropeAttachEnabled',
    'ropeAttachTo',
    'ropeCreate',
    'ropeCut',
    'ropeDestroy',
    'ropeDetach',
    'ropeEndPosition',
    'ropeLength',
    'ropes',
    'ropesAttachedTo',
    'ropeSegments',
    'ropeUnwind',
    'ropeUnwound',
    'rotorsForcesRTD',
    'rotorsRpmRTD',
    'round',
    'runInitScript',
    'safeZoneH',
    'safeZoneW',
    'safeZoneWAbs',
    'safeZoneX',
    'safeZoneXAbs',
    'safeZoneY',
    'save3DENInventory',
    'saveGame',
    'saveIdentity',
    'saveJoysticks',
    'saveMissionProfileNamespace',
    'saveOverlay',
    'saveProfileNamespace',
    'saveStatus',
    'saveVar',
    'savingEnabled',
    'say',
    'say2D',
    'say3D',
    'scopeName',
    'score',
    'scoreSide',
    'screenshot',
    'screenToWorld',
    'scriptDone',
    'scriptName',
    'scudState',
    'secondaryWeapon',
    'secondaryWeaponItems',
    'secondaryWeaponMagazine',
    'select',
    'selectBestPlaces',
    'selectDiarySubject',
    'selectedEditorObjects',
    'selectEditorObject',
    'selectionNames',
    'selectionPosition',
    'selectionVectorDirAndUp',
    'selectLeader',
    'selectMax',
    'selectMin',
    'selectNoPlayer',
    'selectPlayer',
    'selectRandom',
    'selectRandomWeighted',
    'selectWeapon',
    'selectWeaponTurret',
    'sendAUMessage',
    'sendSimpleCommand',
    'sendTask',
    'sendTaskResult',
    'sendUDPMessage',
    'sentencesEnabled',
    'serverCommand',
    'serverCommandAvailable',
    'serverCommandExecutable',
    'serverName',
    'serverNamespace',
    'serverTime',
    'set',
    'set3DENAttribute',
    'set3DENAttributes',
    'set3DENGrid',
    'set3DENIconsVisible',
    'set3DENLayer',
    'set3DENLinesVisible',
    'set3DENLogicType',
    'set3DENMissionAttribute',
    'set3DENMissionAttributes',
    'set3DENModelsVisible',
    'set3DENObjectType',
    'set3DENSelected',
    'setAccTime',
    'setActualCollectiveRTD',
    'setAirplaneThrottle',
    'setAirportSide',
    'setAmmo',
    'setAmmoCargo',
    'setAmmoOnPylon',
    'setAnimSpeedCoef',
    'setAperture',
    'setApertureNew',
    'setArmoryPoints',
    'setAttributes',
    'setAutonomous',
    'setBehaviour',
    'setBehaviourStrong',
    'setBleedingRemaining',
    'setBrakesRTD',
    'setCameraInterest',
    'setCamShakeDefParams',
    'setCamShakeParams',
    'setCamUseTi',
    'setCaptive',
    'setCenterOfMass',
    'setCollisionLight',
    'setCombatBehaviour',
    'setCombatMode',
    'setCompassOscillation',
    'setConvoySeparation',
    'setCruiseControl',
    'setCuratorCameraAreaCeiling',
    'setCuratorCoef',
    'setCuratorEditingAreaType',
    'setCuratorWaypointCost',
    'setCurrentChannel',
    'setCurrentTask',
    'setCurrentWaypoint',
    'setCustomAimCoef',
    'SetCustomMissionData',
    'setCustomSoundController',
    'setCustomWeightRTD',
    'setDamage',
    'setDammage',
    'setDate',
    'setDebriefingText',
    'setDefaultCamera',
    'setDestination',
    'setDetailMapBlendPars',
    'setDiaryRecordText',
    'setDiarySubjectPicture',
    'setDir',
    'setDirection',
    'setDrawIcon',
    'setDriveOnPath',
    'setDropInterval',
    'setDynamicSimulationDistance',
    'setDynamicSimulationDistanceCoef',
    'setEditorMode',
    'setEditorObjectScope',
    'setEffectCondition',
    'setEffectiveCommander',
    'setEngineRpmRTD',
    'setFace',
    'setFaceanimation',
    'setFatigue',
    'setFeatureType',
    'setFlagAnimationPhase',
    'setFlagOwner',
    'setFlagSide',
    'setFlagTexture',
    'setFog',
    'setForceGeneratorRTD',
    'setFormation',
    'setFormationTask',
    'setFormDir',
    'setFriend',
    'setFromEditor',
    'setFSMVariable',
    'setFuel',
    'setFuelCargo',
    'setGroupIcon',
    'setGroupIconParams',
    'setGroupIconsSelectable',
    'setGroupIconsVisible',
    'setGroupid',
    'setGroupIdGlobal',
    'setGroupOwner',
    'setGusts',
    'setHideBehind',
    'setHit',
    'setHitIndex',
    'setHitPointDamage',
    'setHorizonParallaxCoef',
    'setHUDMovementLevels',
    'setHumidity',
    'setIdentity',
    'setImportance',
    'setInfoPanel',
    'setLeader',
    'setLightAmbient',
    'setLightAttenuation',
    'setLightBrightness',
    'setLightColor',
    'setLightConePars',
    'setLightDayLight',
    'setLightFlareMaxDistance',
    'setLightFlareSize',
    'setLightIntensity',
    'setLightIR',
    'setLightnings',
    'setLightUseFlare',
    'setLightVolumeShape',
    'setLocalWindParams',
    'setMagazineTurretAmmo',
    'setMarkerAlpha',
    'setMarkerAlphaLocal',
    'setMarkerBrush',
    'setMarkerBrushLocal',
    'setMarkerColor',
    'setMarkerColorLocal',
    'setMarkerDir',
    'setMarkerDirLocal',
    'setMarkerPolyline',
    'setMarkerPolylineLocal',
    'setMarkerPos',
    'setMarkerPosLocal',
    'setMarkerShadow',
    'setMarkerShadowLocal',
    'setMarkerShape',
    'setMarkerShapeLocal',
    'setMarkerSize',
    'setMarkerSizeLocal',
    'setMarkerText',
    'setMarkerTextLocal',
    'setMarkerType',
    'setMarkerTypeLocal',
    'setMass',
    'setMaxLoad',
    'setMimic',
    'setMissileTarget',
    'setMissileTargetPos',
    'setMousePosition',
    'setMusicEffect',
    'setMusicEventHandler',
    'setName',
    'setNameSound',
    'setObjectArguments',
    'setObjectMaterial',
    'setObjectMaterialGlobal',
    'setObjectProxy',
    'setObjectScale',
    'setObjectTexture',
    'setObjectTextureGlobal',
    'setObjectViewDistance',
    'setOpticsMode',
    'setOvercast',
    'setOwner',
    'setOxygenRemaining',
    'setParticleCircle',
    'setParticleClass',
    'setParticleFire',
    'setParticleParams',
    'setParticleRandom',
    'setPilotCameraDirection',
    'setPilotCameraRotation',
    'setPilotCameraTarget',
    'setPilotLight',
    'setPiPEffect',
    'setPiPViewDistance',
    'setPitch',
    'setPlateNumber',
    'setPlayable',
    'setPlayerRespawnTime',
    'setPlayerVoNVolume',
    'setPos',
    'setPosASL',
    'setPosASL2',
    'setPosASLW',
    'setPosATL',
    'setPosition',
    'setPosWorld',
    'setPylonLoadout',
    'setPylonsPriority',
    'setRadioMsg',
    'setRain',
    'setRainbow',
    'setRandomLip',
    'setRank',
    'setRectangular',
    'setRepairCargo',
    'setRotorBrakeRTD',
    'setShadowDistance',
    'setShotParents',
    'setSide',
    'setSimpleTaskAlwaysVisible',
    'setSimpleTaskCustomData',
    'setSimpleTaskDescription',
    'setSimpleTaskDestination',
    'setSimpleTaskTarget',
    'setSimpleTaskType',
    'setSimulWeatherLayers',
    'setSize',
    'setSkill',
    'setSlingLoad',
    'setSoundEffect',
    'setSpeaker',
    'setSpeech',
    'setSpeedMode',
    'setStamina',
    'setStaminaScheme',
    'setStatValue',
    'setSuppression',
    'setSystemOfUnits',
    'setTargetAge',
    'setTaskMarkerOffset',
    'setTaskResult',
    'setTaskState',
    'setTerrainGrid',
    'setTerrainHeight',
    'setText',
    'setTimeMultiplier',
    'setTiParameter',
    'setTitleEffect',
    'setTowParent',
    'setTrafficDensity',
    'setTrafficDistance',
    'setTrafficGap',
    'setTrafficSpeed',
    'setTriggerActivation',
    'setTriggerArea',
    'setTriggerInterval',
    'setTriggerStatements',
    'setTriggerText',
    'setTriggerTimeout',
    'setTriggerType',
    'setTurretLimits',
    'setTurretOpticsMode',
    'setType',
    'setUnconscious',
    'setUnitAbility',
    'setUnitCombatMode',
    'setUnitFreefallHeight',
    'setUnitLoadout',
    'setUnitPos',
    'setUnitPosWeak',
    'setUnitRank',
    'setUnitRecoilCoefficient',
    'setUnitTrait',
    'setUnloadInCombat',
    'setUserActionText',
    'setUserMFDText',
    'setUserMFDValue',
    'setVariable',
    'setVectorDir',
    'setVectorDirAndUp',
    'setVectorUp',
    'setVehicleAmmo',
    'setVehicleAmmoDef',
    'setVehicleArmor',
    'setVehicleCargo',
    'setVehicleId',
    'setVehicleLock',
    'setVehiclePosition',
    'setVehicleRadar',
    'setVehicleReceiveRemoteTargets',
    'setVehicleReportOwnPosition',
    'setVehicleReportRemoteTargets',
    'setVehicleTiPars',
    'setVehicleVarName',
    'setVelocity',
    'setVelocityModelSpace',
    'setVelocityTransformation',
    'setViewDistance',
    'setVisibleIfTreeCollapsed',
    'setWantedRPMRTD',
    'setWaves',
    'setWaypointBehaviour',
    'setWaypointCombatMode',
    'setWaypointCompletionRadius',
    'setWaypointDescription',
    'setWaypointForceBehaviour',
    'setWaypointFormation',
    'setWaypointHousePosition',
    'setWaypointLoiterAltitude',
    'setWaypointLoiterRadius',
    'setWaypointLoiterType',
    'setWaypointName',
    'setWaypointPosition',
    'setWaypointScript',
    'setWaypointSpeed',
    'setWaypointStatements',
    'setWaypointTimeout',
    'setWaypointType',
    'setWaypointVisible',
    'setWeaponReloadingTime',
    'setWeaponZeroing',
    'setWind',
    'setWindDir',
    'setWindForce',
    'setWindStr',
    'setWingForceScaleRTD',
    'setWPPos',
    'show3DIcons',
    'showChat',
    'showCinemaBorder',
    'showCommandingMenu',
    'showCompass',
    'showCuratorCompass',
    'showGps',
    'showHUD',
    'showLegend',
    'showMap',
    'shownArtilleryComputer',
    'shownChat',
    'shownCompass',
    'shownCuratorCompass',
    'showNewEditorObject',
    'shownGps',
    'shownHUD',
    'shownMap',
    'shownPad',
    'shownRadio',
    'shownScoretable',
    'shownSubtitles',
    'shownUAVFeed',
    'shownWarrant',
    'shownWatch',
    'showPad',
    'showRadio',
    'showScoretable',
    'showSubtitles',
    'showUAVFeed',
    'showWarrant',
    'showWatch',
    'showWaypoint',
    'showWaypoints',
    'side',
    'sideChat',
    'sideRadio',
    'simpleTasks',
    'simulationEnabled',
    'simulCloudDensity',
    'simulCloudOcclusion',
    'simulInClouds',
    'simulWeatherSync',
    'sin',
    'size',
    'sizeOf',
    'skill',
    'skillFinal',
    'skipTime',
    'sleep',
    'sliderPosition',
    'sliderRange',
    'sliderSetPosition',
    'sliderSetRange',
    'sliderSetSpeed',
    'sliderSpeed',
    'slingLoadAssistantShown',
    'soldierMagazines',
    'someAmmo',
    'sort',
    'soundVolume',
    'spawn',
    'speaker',
    'speechVolume',
    'speed',
    'speedMode',
    'splitString',
    'sqrt',
    'squadParams',
    'stance',
    'startLoadingScreen',
    'stop',
    'stopEngineRTD',
    'stopped',
    'str',
    'sunOrMoon',
    'supportInfo',
    'suppressFor',
    'surfaceIsWater',
    'surfaceNormal',
    'surfaceTexture',
    'surfaceType',
    'swimInDepth',
    'switchableUnits',
    'switchAction',
    'switchCamera',
    'switchGesture',
    'switchLight',
    'switchMove',
    'synchronizedObjects',
    'synchronizedTriggers',
    'synchronizedWaypoints',
    'synchronizeObjectsAdd',
    'synchronizeObjectsRemove',
    'synchronizeTrigger',
    'synchronizeWaypoint',
    'systemChat',
    'systemOfUnits',
    'systemTime',
    'systemTimeUTC',
    'tan',
    'targetKnowledge',
    'targets',
    'targetsAggregate',
    'targetsQuery',
    'taskAlwaysVisible',
    'taskChildren',
    'taskCompleted',
    'taskCustomData',
    'taskDescription',
    'taskDestination',
    'taskHint',
    'taskMarkerOffset',
    'taskName',
    'taskParent',
    'taskResult',
    'taskState',
    'taskType',
    'teamMember',
    'teamName',
    'teams',
    'teamSwitch',
    'teamSwitchEnabled',
    'teamType',
    'terminate',
    'terrainIntersect',
    'terrainIntersectASL',
    'terrainIntersectAtASL',
    'text',
    'textLog',
    'textLogFormat',
    'tg',
    'time',
    'timeMultiplier',
    'titleCut',
    'titleFadeOut',
    'titleObj',
    'titleRsc',
    'titleText',
    'toArray',
    'toFixed',
    'toLower',
    'toLowerANSI',
    'toString',
    'toUpper',
    'toUpperANSI',
    'triggerActivated',
    'triggerActivation',
    'triggerAmmo',
    'triggerArea',
    'triggerAttachedVehicle',
    'triggerAttachObject',
    'triggerAttachVehicle',
    'triggerDynamicSimulation',
    'triggerInterval',
    'triggerStatements',
    'triggerText',
    'triggerTimeout',
    'triggerTimeoutCurrent',
    'triggerType',
    'trim',
    'turretLocal',
    'turretOwner',
    'turretUnit',
    'tvAdd',
    'tvClear',
    'tvCollapse',
    'tvCollapseAll',
    'tvCount',
    'tvCurSel',
    'tvData',
    'tvDelete',
    'tvExpand',
    'tvExpandAll',
    'tvIsSelected',
    'tvPicture',
    'tvPictureRight',
    'tvSelection',
    'tvSetColor',
    'tvSetCurSel',
    'tvSetData',
    'tvSetPicture',
    'tvSetPictureColor',
    'tvSetPictureColorDisabled',
    'tvSetPictureColorSelected',
    'tvSetPictureRight',
    'tvSetPictureRightColor',
    'tvSetPictureRightColorDisabled',
    'tvSetPictureRightColorSelected',
    'tvSetSelectColor',
    'tvSetSelected',
    'tvSetText',
    'tvSetTooltip',
    'tvSetValue',
    'tvSort',
    'tvSortAll',
    'tvSortByValue',
    'tvSortByValueAll',
    'tvText',
    'tvTooltip',
    'tvValue',
    'type',
    'typeName',
    'typeOf',
    'UAVControl',
    'uiNamespace',
    'uiSleep',
    'unassignCurator',
    'unassignItem',
    'unassignTeam',
    'unassignVehicle',
    'underwater',
    'uniform',
    'uniformContainer',
    'uniformItems',
    'uniformMagazines',
    'uniqueUnitItems',
    'unitAddons',
    'unitAimPosition',
    'unitAimPositionVisual',
    'unitBackpack',
    'unitCombatMode',
    'unitIsUAV',
    'unitPos',
    'unitReady',
    'unitRecoilCoefficient',
    'units',
    'unitsBelowHeight',
    'unitTurret',
    'unlinkItem',
    'unlockAchievement',
    'unregisterTask',
    'updateDrawIcon',
    'updateMenuItem',
    'updateObjectTree',
    'useAIOperMapObstructionTest',
    'useAISteeringComponent',
    'useAudioTimeForMoves',
    'userInputDisabled',
    'values',
    'vectorAdd',
    'vectorCos',
    'vectorCrossProduct',
    'vectorDiff',
    'vectorDir',
    'vectorDirVisual',
    'vectorDistance',
    'vectorDistanceSqr',
    'vectorDotProduct',
    'vectorFromTo',
    'vectorLinearConversion',
    'vectorMagnitude',
    'vectorMagnitudeSqr',
    'vectorModelToWorld',
    'vectorModelToWorldVisual',
    'vectorMultiply',
    'vectorNormalized',
    'vectorUp',
    'vectorUpVisual',
    'vectorWorldToModel',
    'vectorWorldToModelVisual',
    'vehicle',
    'vehicleCargoEnabled',
    'vehicleChat',
    'vehicleMoveInfo',
    'vehicleRadio',
    'vehicleReceiveRemoteTargets',
    'vehicleReportOwnPosition',
    'vehicleReportRemoteTargets',
    'vehicles',
    'vehicleVarName',
    'velocity',
    'velocityModelSpace',
    'verifySignature',
    'vest',
    'vestContainer',
    'vestItems',
    'vestMagazines',
    'viewDistance',
    'visibleCompass',
    'visibleGps',
    'visibleMap',
    'visiblePosition',
    'visiblePositionASL',
    'visibleScoretable',
    'visibleWatch',
    'waves',
    'waypointAttachedObject',
    'waypointAttachedVehicle',
    'waypointAttachObject',
    'waypointAttachVehicle',
    'waypointBehaviour',
    'waypointCombatMode',
    'waypointCompletionRadius',
    'waypointDescription',
    'waypointForceBehaviour',
    'waypointFormation',
    'waypointHousePosition',
    'waypointLoiterAltitude',
    'waypointLoiterRadius',
    'waypointLoiterType',
    'waypointName',
    'waypointPosition',
    'waypoints',
    'waypointScript',
    'waypointsEnabledUAV',
    'waypointShow',
    'waypointSpeed',
    'waypointStatements',
    'waypointTimeout',
    'waypointTimeoutCurrent',
    'waypointType',
    'waypointVisible',
    'weaponAccessories',
    'weaponAccessoriesCargo',
    'weaponCargo',
    'weaponDirection',
    'weaponInertia',
    'weaponLowered',
    'weaponReloadingTime',
    'weapons',
    'weaponsInfo',
    'weaponsItems',
    'weaponsItemsCargo',
    'weaponState',
    'weaponsTurret',
    'weightRTD',
    'WFSideText',
    'wind',
    'windDir',
    'windRTD',
    'windStr',
    'wingsForcesRTD',
    'worldName',
    'worldSize',
    'worldToModel',
    'worldToModelVisual',
    'worldToScreen'
  ];
  
  // list of keywords from:
  // https://community.bistudio.com/wiki/PreProcessor_Commands
  const PREPROCESSOR = {
    className: 'meta',
    begin: /#\s*[a-z]+\b/,
    end: /$/,
    keywords: 'define undef ifdef ifndef else endif include if',
    contains: [
      {
        begin: /\\\n/,
        relevance: 0
      },
      hljs.inherit(STRINGS, { className: 'string' }),
      {
        begin: /<[^\n>]*>/,
        end: /$/,
        illegal: '\\n'
      },
      hljs.C_LINE_COMMENT_MODE,
      hljs.C_BLOCK_COMMENT_MODE
    ]
  };
  
  return {
    name: 'SQF',
    case_insensitive: true,
    keywords: {
      keyword: KEYWORDS,
      built_in: BUILT_IN,
      literal: LITERAL
    },
    contains: [
      hljs.C_LINE_COMMENT_MODE,
      hljs.C_BLOCK_COMMENT_MODE,
      hljs.NUMBER_MODE,
      VARIABLE,
      FUNCTION,
      STRINGS,
      PREPROCESSOR
    ],
    illegal: [
      //$ is only valid when used with Hex numbers (e.g. $FF)
      /\$[^a-fA-F0-9]/, 
      /\w\$/,
      /\?/,      //There's no ? in SQF
      /@/,       //There's no @ in SQF
      // Brute-force-fixing the build error. See https://github.com/highlightjs/highlight.js/pull/3193#issuecomment-843088729
      / \| /,
      // . is only used in numbers
      /[a-zA-Z_]\./,
      /\:\=/,
      /\[\:/
    ]
  };
}

module.exports = sqf;
